<div *ngIf="formField.type && formField.type =='email'">
  <label for = formField.name [formGroup] = 'buildedForm'>{{formField.name}}
    <input type = 'email' id = formField.name name = formField.name [formControlName]=formField.name>
    </label>
</div>
<div *ngIf="formField.type && formField.type =='string'">
  <label for = formField.name [formGroup] = 'buildedForm'>{{formField.name}}
    <input type = 'text' id = formField.name name = formField.name [formControlName]=formField.name>
    </label>
</div>
<div *ngIf="formField.type && formField.type =='phone'">
  <label for = formField.name [formGroup] = 'buildedForm'>{{formField.name}}
    <input type = 'phone' id = formField.name name = formField.name [formControlName]=formField.name>
    </label>
</div>
<div *ngIf="formField.type && formField.type =='boolean'">
  <label for = formField.name [formGroup] = 'buildedForm'>{{formField.name}}
    <input type="checkbox" id=formField.name name=formField.name value=formField.name [formControlName]=formField.name>
    </label>
</div>

<div *ngIf="formField.type && formField.type =='radio'" >
  <label for="gender">{{formField.name}}</label>
  <div *ngFor="let option of formField.options">
    <input type="radio" value=option.value name=formField.name formControlName=formField.name>
    <label for= option.value>  {{option.label}}</label>
 </div>
</div>
<div *ngIf="formField.type && formField.type =='select'" >
  <label for="gender">{{formField.name}}</label>
<select formControlName= formField.name>
  <option *ngFor="let option of formField.options" value=option.value>{{option.label}}</option>
</select>
</div>

<div *ngIf="formField.itemtype" >
  <label for= formField.name>{{formField.name}}</label>
  <ng-select class="form-control custom-ng "np
  [items]="items"
  placeholder="Please Select"
  (change) = "updateItems()"
  typeToSearchText ="Type to search.."
  [minTermLength] = 3
  [clearSearchOnAdd] = true
  [closeOnSelect] = true
  [searchable]="true"
  bindLabel="name"
  bindValue="id"
  loadingText="Loading..."
  formControlName={{formField.name}}>
</ng-select>
</div>



