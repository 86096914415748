<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
    <div class="container" *ngIf="!loading">
        <div class="row">
            <div class="col-lg-12">
                <div class="bg-white shadow rounded">
                    <div class="row">
                        <div class="col-md-7 pe-0">
                            <div class="form-left h-100 py-5 px-5">
  
                                <div class="row text-end" style="padding:10px;" >
                                    <div class="col-md-12">
                                        <img *ngIf="environment.languages.includes('en')" src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                        <img *ngIf="environment.languages.includes('de')"  src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                        <img *ngIf="environment.languages.includes('ro')"  src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                                </div>   
       
                              </div>
                              <div class="row mobile-logo">
                                <div class="col-12 text-center" style="margin-bottom:30px;">
                                    <img src="../../assets/images/logo-vertical.png" width="30%">
                                </div>
                            </div>                     
                                
                              <div *ngIf="successActivate">
                                <div class="row mt-5">
                                  <div class="col-12 text-center">
                                    <i class="fa fa-check-circle fa-lg success-icon" aria-hidden="true"></i>
                                  </div>
                              </div>
                                  
                                  <div class="row p-3 text-center">
                                  <p>{{'register.account_activated' | translate}}</p>
                                  </div>
                                  
                                  <div class="row">
                                   <div class="col-lg-4 col-sm-12 mx-auto text-center mb-5">
                                      <a [routerLink]="['/login']" class="btn btn-primary "> {{'login.login' | translate}}</a>
                                   </div>
                                  </div>
                              </div>
                              <div *ngIf ="!successActivate">
                                <div class="row mt-5">
                                    <div class="col-12 text-center">
                                        <i class="fa fa-exclamation-circle error-icon" aria-hidden="true"></i>
                                    </div>
                                </div>
                                    
                                    <div class="row p-3 text-center">
                                    <p>{{'register.invalid_link' | translate}}</p>
                                    </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-md-5 ps-0 d-none d-md-block">
                            <div class="form-right h-100 bg-primary text-white text-center pt-5">
                              <img src="../../assets/images/logo-vertical.png" width="50%">
  
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>

  
