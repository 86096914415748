import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/_services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-user-inactive',
  templateUrl: './user-inactive.component.html',
  styleUrls: ['./user-inactive.component.scss']
})
export class UserInactiveComponent  {
  loading = true;
  environment = environment;
  mailSent = false;
  mailAddress = '';
  constructor(
      private activatedRoute: ActivatedRoute,
      private userService: UserService,
      private toastr: ToastrService,
      private translate: TranslateService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
        this.mailAddress = params[`mail`];
    });
  }

resendMail(){
    const body = new HttpParams()
    .set('email', this.mailAddress);
    this.userService.userSendActivate(body)
    .subscribe({
        next: data => {
            this.loading = false;
            if (data[`success`] === true) {
                this.mailSent = true;
            } else {
                this.toastr.error(this.translate.instant('global.global_error'));
            }
        },
        error: () => {
            this.loading = false;
            this.toastr.error(this.translate.instant('global.global_error'));
        }
    });

}
}
