<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
  <div class="container-xxl">
      <div class="row">
          <div class="col-lg-8 mx-auto">
              <div class="bg-white shadow rounded">
                  <div class="row">
                      <div class="col-md-6 pe-0">
                        <div class="row text-end" style="padding:10px;" >
                            <div class="col-md-12">
                                    <img *ngIf="environment.languages.includes('en')" src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                    <img *ngIf="environment.languages.includes('de')"  src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                    <img *ngIf="environment.languages.includes('ro')"  src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                            </div>  
 
                        </div>
                          <div class="form-left h-100 py-5 px-5">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="row mobile-logo">
                                    <div class="col-12 text-center" style="margin-bottom:30px;">
                                        <img src="../../assets/images/logo-vertical.png" width="30%">
                                    </div>
                                </div>
                              <div class="row input-row">
                                      <div class="col-12">
                                          <div class="input-group">
                                              <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></div>
                                              <input type="text" class="form-control" formControlName="username" placeholder= "{{ 'login.enter_username' | translate }}" >
                                          </div>
                                          <div *ngIf="f.username.invalid && submitted">
                                            <div *ngIf="f.username.errors?.required"  class="text-danger">{{ 'register.validator_required' | translate: {value: 'login.username' | translate } }}</div>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="row  input-row">
                                      <div class="col-12">
                                        <div class="input-group">
                                            <div class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></div>
                                            <input type="password" class="form-control" formControlName="password" placeholder="{{ 'register.password' | translate }}">
                                        </div>
                                        <div *ngIf="f.password.invalid && submitted">
                                            <div class="invalid-feedback"></div>
                                          <div *ngIf="f.password.errors?.required"  class="text-danger">{{ 'register.validator_required' | translate: {value: 'register.password' | translate } }}</div>
                                        </div>
                                      </div>

                                      </div>
                                      <div class="row  input-row">
                                      <div class="col-sm-6">
                                          <div class="form-check">
                                              <input class="form-check-input" type="checkbox" id="inlineFormCheck" (change)="rememberMe = !rememberMe" [checked]="rememberMe">
                                              <label class="form-check-label" for="inlineFormCheck">{{ 'login.remember_me' | translate }}</label>
                                          </div>
                                      </div>

                                      <div class="col-sm-6">
                                          <a [routerLink]="['/passforgot']" class="float-end text-primary">{{'login.forgot_passwd' | translate}}?</a>
                                      </div>
                                      </div>
                                      <div class="row mb-5">
                                      <div class="col-12">
                                          <button [disabled]="loading" type="submit" class="btn btn-primary px-4 float-end mt-4">{{'login.login' | translate}}<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
                                      </div>
                                    </div>
                                    <div class="row  input-row">
                                      <div class="col-sm-12">
                                          <div class="form-check">
                                              <div >{{'login.dont_have_account' | translate}}  <a [routerLink]="['/register']"> {{'login.sign_up' | translate}}</a> </div>
                                          </div>
                                      </div>
                                      </div>
                              </form>
                          </div>
                      </div>
                      <div class="col-md-6 ps-0 d-none d-md-block">
                          <div class="form-right h-100 bg-primary text-white text-center pt-5">
                            <img src="../../assets/images/logo-vertical.png" width="40%">
                            <div class="register-list px-5 w-75 mx-auto">
                                <ul class="list-unstyled text-start mt-5">
                                  <li class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.invoice_module' | translate}}</li>
                                  <hr style="margin:0.5em 0">
                                  <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.manage_contacts' | translate}} </li>
                                  <hr style="margin:0.5em 0">
                                  <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.webpage_creator' | translate}}</li>
                                  <hr style="margin:0.5em 0">
                                  <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.analytics' | translate}}</li>
                                </ul>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
          </div>
      </div>
  </div>
</div>
