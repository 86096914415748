<div class="terms">ALLGEMEINE GESCHÄFTSBEDINGUNGEN
    Zuletzt aktualisiert am 10. März 2022
    <br>
    <br><br>
    <strong>1. VEREINBARUNG ZU DEN BEDINGUNGEN</strong>
    <br>
    Diese Nutzungsbedingungen stellen eine rechtlich bindende Vereinbarung dar, die zwischen Ihnen persönlich oder im Namen einer juristischen Person („Sie“) und der M24 Labs GmH („Unternehmen“, „wir“, „uns“ oder „unser“) getroffen wurde Ihren Zugriff auf und Ihre Nutzung der Website https://{{environment.websiteUrl}} sowie aller anderen Medienformen, Medienkanäle, mobilen Websites oder mobilen Anwendungen, die damit in Verbindung stehen, verlinkt oder anderweitig damit verbunden sind (zusammen die „Website“). Wir sind in Deutschland registriert und haben unseren eingetragenen Firmensitz in Leopoldstraße, München, Bayern 80804. Unsere Umsatzsteuer-Identifikationsnummer lautet HRB 230202. Sie stimmen zu, dass Sie durch den Zugriff auf die Website alle diese Bedingungen gelesen und verstanden haben und damit einverstanden sind, an sie gebunden zu sein Verwenden. WENN SIE NICHT MIT ALLEN DIESEN NUTZUNGSBEDINGUNGEN EINVERSTANDEN SIND, IST IHNEN DIE NUTZUNG DER WEBSITE AUSDRÜCKLICH UNTERSAGT, UND SIE MÜSSEN DIE NUTZUNG SOFORT EINSTELLEN.
    Ergänzende Geschäftsbedingungen oder Dokumente, die von Zeit zu Zeit auf der Website veröffentlicht werden, werden hiermit ausdrücklich durch Bezugnahme aufgenommen. Wir behalten uns das Recht vor, nach eigenem Ermessen von Zeit zu Zeit Änderungen oder Modifikationen an diesen Nutzungsbedingungen vorzunehmen. Wir werden Sie über alle Änderungen informieren, indem wir das Datum der „letzten Aktualisierung“ dieser Nutzungsbedingungen aktualisieren, und Sie verzichten auf jedes Recht, eine spezifische Benachrichtigung über jede solche Änderung zu erhalten. Bitte stellen Sie sicher, dass Sie die geltenden Bedingungen jedes Mal überprüfen, wenn Sie unsere Website nutzen, damit Sie verstehen, welche Bedingungen gelten. Sie unterliegen den Änderungen in allen überarbeiteten Nutzungsbedingungen und gelten als davon Kenntnis genommen und akzeptiert, wenn Sie die Website nach dem Datum der Veröffentlichung dieser überarbeiteten Nutzungsbedingungen weiterhin nutzen.
    Die auf der Website bereitgestellten Informationen sind nicht für die Weitergabe an oder die Nutzung durch Personen oder Organisationen in Rechtsordnungen oder Ländern bestimmt, in denen eine solche Weitergabe oder Nutzung gegen Gesetze oder Vorschriften verstoßen würde oder die uns einer Registrierungspflicht in einer solchen Rechtsordnung oder einem solchen Land unterwerfen würden . Dementsprechend tun diejenigen Personen, die von anderen Standorten aus auf die Website zugreifen, dies aus eigener Initiative und sind allein verantwortlich für die Einhaltung der lokalen Gesetze, sofern und soweit lokale Gesetze anwendbar sind.
    Die Website ist für Benutzer bestimmt, die mindestens 18 Jahre alt sind. Personen unter 18 Jahren ist die Nutzung oder Registrierung für die Website nicht gestattet.
    <br><br>
    <strong>2. GEISTIGE EIGENTUMSRECHTE</strong>
    <br>
    Sofern nicht anders angegeben, ist die Website unser Eigentum und sämtlicher Quellcode, Datenbanken, Funktionen, Software, Website-Designs, Audio, Video, Text, Fotos und Grafiken auf der Website (zusammen die „Inhalte“) sowie die Marken und Dienste darin enthaltene Marken und Logos (die „Marken“) sind unser Eigentum oder werden von uns kontrolliert oder an uns lizenziert und sind durch Urheberrechts- und Markengesetze und verschiedene andere geistige Eigentumsrechte und Gesetze gegen unlauteren Wettbewerb der Vereinigten Staaten, internationale Urheberrechtsgesetze, und internationalen Konventionen. Der Inhalt und die Marken werden auf der Website „WIE BESEHEN“ nur zu Ihrer Information und zum persönlichen Gebrauch bereitgestellt. Sofern nicht ausdrücklich in diesen Nutzungsbedingungen vorgesehen, darf kein Teil der Website und keine Inhalte oder Marken kopiert, reproduziert, aggregiert, neu veröffentlicht, hochgeladen, gepostet, öffentlich angezeigt, codiert, übersetzt, übertragen, verteilt, verkauft, lizenziert oder anderweitig ohne unsere ausdrückliche vorherige Zustimmung zu kommerziellen Zwecken verwertet werden
    schriftliche Erlaubnis.
    Vorausgesetzt, dass Sie zur Nutzung der Website berechtigt sind, wird Ihnen eine eingeschränkte Lizenz für den Zugriff auf und die Nutzung der Website und das Herunterladen oder Ausdrucken einer Kopie eines Teils des Inhalts, auf den Sie ordnungsgemäß Zugriff erhalten haben, ausschließlich für Ihren persönlichen, nicht kommerziellen Gebrauch gewährt verwenden. Wir behalten uns alle Rechte vor, die Ihnen nicht ausdrücklich an der Website, den Inhalten und den Marken gewährt werden.
    <br><br>
    <strong>3. NUTZERERKLÄRUNGEN</strong>
    <br>
    Durch die Nutzung der Website versichern und garantieren Sie, dass: (1) alle von Ihnen übermittelten Registrierungsinformationen wahr, genau, aktuell und vollständig sind; (2) Sie werden die Richtigkeit dieser Informationen wahren und diese Registrierungsinformationen bei Bedarf unverzüglich aktualisieren; (3) Sie sind geschäftsfähig und stimmen zu, diese Nutzungsbedingungen einzuhalten; (4) Sie sind in der Gerichtsbarkeit, in der Sie Ihren Wohnsitz haben, nicht minderjährig; (5) Sie werden nicht über automatisierte oder nicht menschliche Mittel auf die Website zugreifen, sei es durch einen Bot, ein Skript oder auf andere Weise; (6) Sie werden die Website nicht für illegale oder unbefugte Zwecke nutzen; und (7) Ihre Nutzung der Website verstößt nicht gegen geltende Gesetze oder Vorschriften.
    Wenn Sie unwahre, ungenaue, nicht aktuelle oder unvollständige Informationen angeben, haben wir das Recht, Ihr Konto zu sperren oder zu kündigen und jegliche aktuelle oder zukünftige Nutzung der Website (oder eines Teils davon) zu verweigern.
    <br><br>
    <strong>4. BENUTZERREGISTRIERUNG</strong>
    <br>
    Möglicherweise müssen Sie sich auf der Website registrieren. Sie stimmen zu, Ihr Passwort vertraulich zu behandeln und sind für die gesamte Nutzung Ihres Kontos und Passworts verantwortlich. Wir behalten uns die Entfernung vore, einen von Ihnen ausgewählten Benutzernamen zurückfordern oder ändern, wenn wir nach unserem alleinigen Ermessen feststellen, dass dieser Benutzername unangemessen, obszön oder anderweitig anstößig ist.
    <br><br>
    <strong>5. GEBÜHREN UND ZAHLUNG</strong>
    <br>
    Wir akzeptieren folgende Zahlungsarten:
    -  Visa
    - Mastercard
    -PayPal
    Möglicherweise müssen Sie einige unserer Dienste erwerben oder eine Gebühr zahlen, um auf sie zugreifen zu können. Sie erklären sich damit einverstanden, aktuelle, vollständige und genaue Kauf- und Kontoinformationen für alle über die Website getätigten Käufe bereitzustellen. Sie erklären sich ferner damit einverstanden, Konto- und Zahlungsinformationen, einschließlich E-Mail-Adresse, Zahlungsmethode und Ablaufdatum der Zahlungskarte, umgehend zu aktualisieren, damit wir Ihre Transaktionen abschließen und Sie bei Bedarf kontaktieren können. Wir stellen Ihnen über die Website getätigte Einkäufe über ein Online-Rechnungskonto in Rechnung. Die Umsatzsteuer wird dem Preis der Einkäufe hinzugefügt, wenn wir dies für erforderlich erachten. Wir können die Preise jederzeit ändern. Alle Zahlungen erfolgen in Euro.
    Sie stimmen zu, alle Abgaben oder Gebühren zu den für Ihre Käufe geltenden Preisen zu zahlen, und Sie autorisieren uns, Ihren gewählten Zahlungsanbieter mit solchen Beträgen bei Ihrem Kauf zu belasten. Wenn Ihr Kauf wiederkehrenden Gebühren unterliegt, stimmen Sie zu, dass wir Ihre Zahlungsmethode regelmäßig belasten, ohne dass Sie für jede wiederkehrende Gebühr Ihre vorherige Zustimmung benötigen, bis Sie uns über Ihre Stornierung informieren.
    Wir behalten uns das Recht vor, Fehler oder Fehler in der Preisangabe zu korrigieren, auch wenn wir bereits eine Zahlung angefordert oder erhalten haben. Wir behalten uns außerdem das Recht vor, Bestellungen abzulehnen, die über die Website aufgegeben werden.
    <br><br>
    <strong>6. STORNIERUNG</strong>
    <br>
    Sie können Ihr Abonnement jederzeit kündigen, indem Sie uns über die unten angegebenen Kontaktinformationen kontaktieren. Ihre Kündigung wird zum Ende der aktuell bezahlten Laufzeit wirksam.
    Wenn Sie mit unseren Dienstleistungen nicht zufrieden sind, senden Sie uns bitte eine E-Mail an support@{{environment.websiteUrl}} oder rufen Sie uns unter +49 172 9912415 an.
    <br><br>
    <strong>7. VERBOTENE AKTIVITÄTEN</strong>
    <br>
    Sie dürfen die Website nicht für andere Zwecke als die, für die wir die Website zur Verfügung stellen, aufrufen oder nutzen. Die Website darf nicht in Verbindung mit kommerziellen Aktivitäten verwendet werden, außer denen, die von uns ausdrücklich unterstützt oder genehmigt werden.
    Als Benutzer der Website erklären Sie sich damit einverstanden, Folgendes nicht zu tun:
    * Daten oder andere Inhalte systematisch von der Website abzurufen, um direkt oder indirekt eine Sammlung, Zusammenstellung, Datenbank oder ein Verzeichnis ohne schriftliche Genehmigung von uns zu erstellen oder zusammenzustellen.
    * uns und andere Benutzer auszutricksen, zu betrügen oder in die Irre zu führen, insbesondere bei jedem Versuch, vertrauliche Kontoinformationen wie Benutzerpasswörter in Erfahrung zu bringen.
    * Sicherheitsrelevante Funktionen der Website umgehen, deaktivieren oder anderweitig stören, einschließlich Funktionen, die die Nutzung oder das Kopieren von Inhalten verhindern oder einschränken oder Beschränkungen der Nutzung der Website und/oder der darin enthaltenen Inhalte durchsetzen.
    * Unserer Meinung nach uns und/oder die Website herabsetzen, beflecken oder anderweitig schädigen.
    * Verwenden Sie von der Website erhaltene Informationen, um eine andere Person zu belästigen, zu missbrauchen oder zu verletzen.
    * Unsere Support-Services missbräuchlich nutzen oder falsche Missbrauchs- oder Fehlverhaltensmeldungen einreichen.
    * Verwenden Sie die Website in einer Weise, die nicht mit geltenden Gesetzen oder Vorschriften vereinbar ist.
    * Beteiligen Sie sich an nicht autorisiertem Framing oder Verlinken der Website.
    * Hochladen oder Übertragen (oder Versuch des Hochladens oder Übertragens) von Viren, Trojanischen Pferden oder anderem Material, einschließlich übermäßiger Verwendung von Großbuchstaben und Spamming (kontinuierliches Posten von sich wiederholendem Text), das die ununterbrochene Nutzung und den Genuss der Website durch Dritte beeinträchtigt oder die Nutzung, Merkmale, Funktionen, den Betrieb oder die Wartung der Website modifiziert, beeinträchtigt, unterbricht, verändert oder stört.
    * Sich an einer automatisierten Nutzung des Systems zu beteiligen, wie z. B. die Verwendung von Skripten zum Senden von Kommentaren oder Nachrichten oder die Verwendung von Data Mining, Robotern oder ähnlichen Datenerfassungs- und Extraktionstools.
    * Löschen Sie den Hinweis auf das Urheberrecht oder andere Eigentumsrechte von allen Inhalten.
    * Versuchen Sie, sich als ein anderer Benutzer oder eine andere Person auszugeben oder den Benutzernamen eines anderen Benutzers zu verwenden.
    * Hochladen oder Übertragen (oder Versuch des Hochladens oder Übertragens) von Material, das als passiver oder aktiver Mechanismus zum Sammeln oder Übertragen von Informationen fungiert, einschließlich, aber nicht beschränkt auf, klare Grafikaustauschformate („Gifs“), 1 × 1-Pixel, Web-Bugs, Cookies oder andere ähnliche Geräte (manchmal als „Spyware“ oder „passive Erfassungsmechanismen“ oder „PCMs“ bezeichnet).
    * Die Site oder die mit der Site verbundenen Netzwerke oder Dienste zu stören, zu unterbrechen oder unangemessen zu belasten.
    * Belästigen, belästigen, einschüchtern oder bedrohen Sie einen unserer Mitarbeiter oder Vertreter, die daran beteiligt sind, Ihnen einen Teil der Website bereitzustellen.
    * Versuchen Sie, alle Maßnahmen der Website zu umgehen, die darauf abzielen, den Zugriff auf die Website oder einen Teil der Website zu verhindern oder einzuschränken.
    * Kopieren oder Anpassen der Software der Website, einschließlich, aber nicht beschränkt auf Flash, PHP, HTML, JavaScript oder anderen Code.
    * Sofern dies nicht durch geltendes Recht gestattet ist, jegliche Software, die die Website umfasst oder in irgendeiner Weise einen Teil davon ausmacht, zu entschlüsseln, zu dekompilieren, zu disassemblieren oder zurückzuentwickeln.
    * Sofern dies nicht das Ergebnis von Standard sein kannd Verwendung von Suchmaschinen oder Internetbrowsern, Verwendung, Start, Entwicklung oder Verbreitung automatisierter Systeme, einschließlich, aber nicht beschränkt auf Spider, Roboter, Cheat-Dienstprogramme, Scraper oder Offline-Reader, die auf die Website zugreifen, oder Verwendung oder Start eines nicht autorisierten Skripts oder andere Software.
    * Verwenden Sie einen Einkaufsagenten oder Einkaufsagenten, um Einkäufe auf der Website zu tätigen.
    * Die Website nicht unbefugt nutzen, einschließlich des Sammelns von Benutzernamen und/oder E-Mail-Adressen von Benutzern auf elektronischem oder anderem Wege zum Zweck des Versendens unerwünschter E-Mails oder des Erstellens von Benutzerkonten auf automatisierte Weise oder unter falschem Vorwand.
    * Verwenden Sie die Website im Rahmen von Bemühungen, mit uns zu konkurrieren, oder verwenden Sie die Website und/oder den Inhalt anderweitig für gewinnbringende Unternehmungen oder kommerzielle Unternehmen.
    <br><br>
    <strong>8. NUTZERGENERIERTE BEITRÄGE</strong>
    <br>
    Die Website bietet Benutzern nicht die Möglichkeit, Inhalte einzureichen oder zu veröffentlichen. Wir können Ihnen die Möglichkeit geben, Inhalte und Materialien zu erstellen, einzureichen, zu posten, anzuzeigen, zu übertragen, aufzuführen, zu veröffentlichen, zu verteilen oder an uns oder auf der Website zu senden, einschließlich, aber nicht beschränkt auf Text, Schriften, Video, Audio, Fotos , Grafiken, Kommentare, Vorschläge oder persönliche Informationen oder anderes Material (zusammen „Beiträge“). Beiträge können von anderen Benutzern der Website und über Websites von Drittanbietern eingesehen werden. Daher können alle von Ihnen übermittelten Beiträge gemäß der Datenschutzrichtlinie der Website behandelt werden. Wenn Sie Beiträge erstellen oder zur Verfügung stellen, versichern und garantieren Sie Folgendes:
    * Die Erstellung, Verteilung, Übertragung, öffentliche Anzeige oder Aufführung sowie der Zugriff auf, das Herunterladen oder Kopieren Ihrer Beiträge verletzen nicht die Eigentumsrechte, einschließlich, aber nicht beschränkt auf Urheberrechte, Patente, Marken, Geschäftsgeheimnisse, oder Urheberpersönlichkeitsrechte Dritter.
    * Sie sind der Ersteller und Eigentümer oder verfügen über die erforderlichen Lizenzen, Rechte, Zustimmungen, Freigaben und Genehmigungen zur Nutzung und autorisieren uns, die Website und andere Benutzer der Website, Ihre Beiträge auf jede von der Website vorgesehene Weise zu verwenden und diese Nutzungsbedingungen.
    * Sie haben die schriftliche Zustimmung, Freigabe und/oder Erlaubnis jeder einzelnen identifizierbaren Einzelperson in Ihren Beiträgen, den Namen oder das Bild jeder einzelnen dieser identifizierbaren Einzelpersonen zu verwenden, um die Aufnahme und Nutzung Ihrer Beiträge auf jede von ihnen vorgesehene Weise zu ermöglichen die Website und diese Nutzungsbedingungen.
    * Ihre Beiträge sind nicht falsch, ungenau oder irreführend.
    * Ihre Beiträge sind keine unaufgeforderte oder nicht autorisierte Werbung, Werbematerialien, Schneeballsysteme, Kettenbriefe, Spam, Massensendungen oder andere Formen der Werbung.
    * Ihre Beiträge sind nicht obszön, unanständig, lasziv, schmutzig, gewalttätig, belästigend, verleumderisch, verleumderisch oder anderweitig anstößig (wie von uns festgestellt).
    * Ihre Beiträge verspotten, verspotten, verunglimpfen, einschüchtern oder beschimpfen niemanden.
    * Ihre Beiträge werden nicht dazu verwendet, andere Personen zu belästigen oder zu bedrohen (im rechtlichen Sinne dieser Begriffe) und Gewalt gegen eine bestimmte Person oder Personengruppe zu fördern.
    * Ihre Beiträge verstoßen nicht gegen geltende Gesetze, Vorschriften oder Regeln.
    * Ihre Beiträge verletzen nicht die Datenschutz- oder Veröffentlichungsrechte Dritter.
    * Ihre Beiträge verstoßen nicht gegen geltendes Recht in Bezug auf Kinderpornografie oder dienen anderweitig dem Schutz der Gesundheit oder des Wohlbefindens von Minderjährigen.
    * Ihre Beiträge enthalten keine beleidigenden Kommentare, die sich auf Rasse, nationale Herkunft, Geschlecht, sexuelle Präferenzen oder körperliche Behinderung beziehen.
    * Ihre Beiträge verstoßen nicht anderweitig gegen Bestimmungen dieser Nutzungsbedingungen oder gegen geltende Gesetze oder Vorschriften oder verlinken auf Material, das gegen diese verstößt.
    
    Jegliche Nutzung der Website unter Verstoß gegen das Vorstehende verstößt gegen diese Nutzungsbedingungen und kann unter anderem zur Kündigung oder Aussetzung Ihrer Rechte zur Nutzung der Website führen.
    <br><br>
    <strong>9. BEITRAGSLIZENZ</strong>
    <br>
    Sie und die Website stimmen zu, dass wir auf alle Informationen und personenbezogenen Daten zugreifen, diese speichern, verarbeiten und verwenden dürfen, die Sie gemäß den Bestimmungen der Datenschutzrichtlinie und Ihren Auswahlmöglichkeiten (einschließlich Einstellungen) bereitstellen.
    
    Indem Sie Vorschläge oder anderes Feedback bezüglich der Website einreichen, stimmen Sie zu, dass wir dieses Feedback für jeden Zweck ohne Entschädigung an Sie verwenden und weitergeben können.
    
    Wir beanspruchen kein Eigentum an Ihren Beiträgen. Sie behalten das volle Eigentum an allen Ihren Beiträgen und allen geistigen Eigentumsrechten oder anderen Eigentumsrechten, die mit Ihren Beiträgen verbunden sind. Wir haften nicht für Aussagen oder Darstellungen in Ihren Beiträgen, die Sie in irgendeinem Bereich der Website bereitstellen. Sie sind allein verantwortlich für Ihre Beiträge zur Website und erklären sich ausdrücklich damit einverstanden, uns von jeglicher Verantwortung zu befreien und von rechtlichen Schritten gegen uns in Bezug auf Ihre Beiträge abzusehen.
    <br><br>
    <strong>10. EINREICHUNGEN</strong>
    <br>
    Sie erkennen an und stimmen zu, dass alle Fragen, Kommentare, Vorschläge, Ideen, Rückmeldungen oder andere Informationen relevant sindDie von Ihnen an uns übermittelten Inhalte auf der Website („Beiträge“) sind nicht vertraulich und gehen in unser alleiniges Eigentum über. Wir besitzen die ausschließlichen Rechte, einschließlich aller geistigen Eigentumsrechte, und sind berechtigt, diese Einsendungen ohne Anerkennung oder Entschädigung für jeden rechtmäßigen Zweck, kommerziell oder anderweitig, uneingeschränkt zu nutzen und zu verbreiten. Sie verzichten hiermit auf alle moralischen Rechte an solchen Einsendungen und garantieren hiermit, dass solche Einsendungen von Ihnen stammen oder dass Sie das Recht haben, solche Einsendungen einzureichen. Sie erklären sich damit einverstanden, dass wir wegen angeblicher oder tatsächlicher Verletzung oder Veruntreuung von Eigentumsrechten an Ihren Einsendungen nicht in Anspruch genommen werden.
    <br><br>
    <strong>11. WEBSITE-VERWALTUNG</strong>
    <br>
    Wir behalten uns das Recht, aber nicht die Verpflichtung vor: (1) die Website auf Verstöße gegen diese Nutzungsbedingungen zu überwachen; (2) geeignete rechtliche Schritte gegen jeden einzuleiten, der nach unserem alleinigen Ermessen gegen das Gesetz oder diese Nutzungsbedingungen verstößt, einschließlich, aber nicht beschränkt auf die Meldung eines solchen Benutzers an die Strafverfolgungsbehörden; (3) nach unserem alleinigen Ermessen und ohne Einschränkung, Ihre Beiträge oder Teile davon abzulehnen, den Zugriff darauf einzuschränken, die Verfügbarkeit einzuschränken oder (soweit technisch machbar) zu deaktivieren; (4) nach unserem alleinigen Ermessen und ohne Einschränkung, Benachrichtigung oder Haftung alle Dateien und Inhalte, die übermäßig groß sind oder unsere Systeme in irgendeiner Weise belasten, von der Website zu entfernen oder anderweitig zu deaktivieren; und (5) die Website anderweitig so verwalten, dass unsere Rechte und unser Eigentum geschützt und das ordnungsgemäße Funktionieren der Website erleichtert werden.
    <br><br>
    <strong>12. DATENSCHUTZERKLÄRUNG</strong>
    <br>
    Wir kümmern uns um Datenschutz und Sicherheit. Durch die Nutzung der Website stimmen Sie zu, an unsere auf der Website veröffentlichte Datenschutzrichtlinie gebunden zu sein, die in diese Nutzungsbedingungen integriert ist. Bitte beachten Sie, dass die Website in gehostet wird
    Deutschland. Wenn Sie von einer anderen Region der Welt aus auf die Website zugreifen, deren Gesetze oder andere Anforderungen die Erfassung, Nutzung oder Offenlegung personenbezogener Daten regeln und die von den geltenden Gesetzen in Deutschland abweichen, übertragen Sie Ihre Daten durch Ihre fortgesetzte Nutzung der Website nach Deutschland , und Sie stimmen zu, dass Ihre Daten nach Deutschland übertragen und dort verarbeitet werden.
    <br><br>
    <strong>13. URHEBERRECHTSVERLETZUNGEN</strong>
    <br>
    Wir respektieren die geistigen Eigentumsrechte anderer. Wenn Sie der Meinung sind, dass auf oder über die Website verfügbares Material ein Urheberrecht verletzt, das Sie besitzen oder kontrollieren, benachrichtigen Sie uns bitte unverzüglich über die unten angegebenen Kontaktinformationen (eine „Benachrichtigung“). Eine Kopie Ihrer Benachrichtigung wird an die Person gesendet, die das in der Benachrichtigung angesprochene Material veröffentlicht oder gespeichert hat. Bitte beachten Sie, dass Sie gemäß geltendem Recht für Schäden haftbar gemacht werden können, wenn Sie in einer Benachrichtigung wesentliche falsche Angaben machen. Wenn Sie sich also nicht sicher sind, dass Material, das sich auf der Website befindet oder mit ihr verlinkt ist, Ihr Urheberrecht verletzt, sollten Sie zunächst in Betracht ziehen, sich an einen Anwalt zu wenden.
    <br><br>
    <strong>14. LAUFZEIT UND KÜNDIGUNG</strong>
    <br>
    Diese Nutzungsbedingungen bleiben in vollem Umfang in Kraft, während Sie die Website nutzen. OHNE EINSCHRÄNKUNG ANDERER BESTIMMUNGEN DIESER NUTZUNGSBEDINGUNGEN BEHALTEN WIR UNS DAS RECHT VOR, NACH UNSEREM ALLEINIGEN ERMESSEN UND OHNE ANKÜNDIGUNG ODER HAFTUNG JEDER PERSON AUS IRGENDEINEM GRUND ODER OHNE GRUND, EINSCHLIESSLICH UND OHNE EINSCHRÄNKUNG BEI VERLETZUNG JEGLICHER ZUSICHERUNGEN, GEWÄHRLEISTUNGEN ODER ZUSICHERUNGEN, DIE IN DIESEN NUTZUNGSBEDINGUNGEN ENTHALTEN SIND, ODER JEGLICHER ANWENDBAREN GESETZE ODER VORSCHRIFTEN. WIR KÖNNEN IHRE NUTZUNG ODER TEILNAHME AN DER WEBSITE BEENDEN ODER IHR KONTO UND JEGLICHE INHALTE ODER INFORMATIONEN, DIE SIE JEDERZEIT, OHNE WARNUNG, NACH UNSEREM ALLEINIGEN ERMESSEN GEPOSTET LÖSCHEN.
    
    Wenn wir Ihr Konto aus irgendeinem Grund kündigen oder sperren, ist es Ihnen untersagt, sich unter Ihrem Namen, einem falschen oder geliehenen Namen oder dem Namen eines Dritten zu registrieren und ein neues Konto zu erstellen, selbst wenn Sie möglicherweise im Namen des Dritten handeln Gruppe. Zusätzlich zur Kündigung oder Sperrung Ihres Kontos behalten wir uns das Recht vor, geeignete rechtliche Schritte einzuleiten, einschließlich, aber nicht beschränkt auf zivil-, straf- und einstweilige Rechtsbehelfe.
    <br><br>
    <strong>15. ÄNDERUNGEN UND UNTERBRECHUNGEN</strong>
    <br>
    Wir behalten uns das Recht vor, den Inhalt der Website jederzeit und aus beliebigem Grund nach eigenem Ermessen ohne Vorankündigung zu ändern, zu modifizieren oder zu entfernen. Wir sind jedoch nicht verpflichtet, Informationen auf unserer Website zu aktualisieren. Wir behalten uns außerdem das Recht vor, die Website ganz oder teilweise ohne Vorankündigung jederzeit zu ändern oder einzustellen. Wir haften Ihnen oder Dritten gegenüber nicht für Modifikationen, Preisänderungen, Aussetzungen oder Einstellung der Website.
    
    Wir können nicht garantieren, dass die Website jederzeit verfügbar ist. Es können Hardware-, Software- oder andere Probleme auftreten oder wir müssen Wartungsarbeiten im Zusammenhang mit der Website durchführen, was zu Unterbrechungen, Verzögerungen oder Fehlern führt. Wir behalten uns das Recht vor, die Website jederzeit zu ändern, zu überarbeiten, zu aktualisieren, auszusetzen, einzustellen oder anderweitig zu modifizierensofort oder aus irgendeinem Grund ohne Benachrichtigung an Sie. Sie stimmen zu, dass wir keinerlei Haftung für Verluste, Schäden oder Unannehmlichkeiten übernehmen, die dadurch verursacht werden, dass Sie während einer Ausfallzeit oder Unterbrechung der Website nicht auf die Website zugreifen oder diese nutzen können. Nichts in diesen Nutzungsbedingungen ist so auszulegen, dass es uns verpflichtet, die Website zu warten und zu unterstützen oder Korrekturen, Aktualisierungen oder Veröffentlichungen in Verbindung damit bereitzustellen.
    <br><br>
    <strong>16. GELTENDES RECHT</strong>
    <br>
    Diese Bedingungen unterliegen deutschem Recht und werden entsprechend ausgelegt, und die Anwendung des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf wird ausdrücklich ausgeschlossen. Wenn Sie Ihren gewöhnlichen Aufenthalt in der EU haben und Sie Verbraucher sind, genießen Sie zusätzlich den Schutz, der Ihnen durch zwingende Bestimmungen des Rechts Ihres Wohnsitzlandes gewährt wird. Sowohl die M24 Labs GmH als auch Sie unterwerfen sich der nicht ausschließlichen Zuständigkeit der Gerichte von München, was bedeutet, dass Sie in Deutschland oder im EU-Land eine Klage zur Verteidigung Ihrer Verbraucherschutzrechte in Bezug auf diese Nutzungsbedingungen erheben können in dem Sie wohnen.
    <br><br>
    <strong>17. STREITBEILEGUNG</strong>
    <br>
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit, die Sie hier finden: https://ec.europa.eu/consumers/odr. Wenn Sie uns auf dieses Thema aufmerksam machen möchten, kontaktieren Sie uns bitte.
    <br><br>
    <strong>18. KORREKTUREN</strong>
    <br>
    Es kann Informationen auf der Website geben, die typografische Fehler, Ungenauigkeiten oder Auslassungen enthalten, einschließlich Beschreibungen, Preise, Verfügbarkeit und verschiedener anderer Informationen. Wir behalten uns das Recht vor, Fehler, Ungenauigkeiten oder Auslassungen zu korrigieren und die Informationen auf der Website jederzeit ohne vorherige Ankündigung zu ändern oder zu aktualisieren.
    <br><br>
    <strong>19. HAFTUNGSAUSSCHLUSS</strong>
    <br>
    DIE WEBSITE WIRD OHNE MÄNGELGEWÄHR UND WIE VERFÜGBAR BEREITGESTELLT. SIE STIMMEN ZU, DASS IHRE NUTZUNG DER WEBSITE UND UNSERER DIENSTE AUF IHR EIGENES RISIKO ERFOLGT. IM VOLLEN GESETZLICH ZULÄSSIGEN UMFANG SCHLIESSEN WIR ALLE AUSDRÜCKLICHEN ODER STILLSCHWEIGENDEN GEWÄHRLEISTUNGEN IN VERBINDUNG MIT DER WEBSITE UND IHRER VERWENDUNG AUS, EINSCHLIESSLICH UND OHNE EINSCHRÄNKUNG DIE STILLSCHWEIGENDEN GEWÄHRLEISTUNGEN DER MARKTFÄHIGKEIT, EIGNUNG FÜR EINEN BESTIMMTEN ZWECK UND NICHTVERLETZUNG VON RECHTEN DRITTER. WIR GEBEN KEINE GEWÄHRLEISTUNGEN ODER ZUSICHERUNGEN ÜBER DIE GENAUIGKEIT ODER VOLLSTÄNDIGKEIT DES INHALTS DER WEBSITE ODER DES INHALTS VON WEBSITES, DIE MIT DER WEBSITE VERLINKT WERDEN, UND WIR ÜBERNEHMEN KEINE HAFTUNG ODER VERANTWORTUNG FÜR (1) FEHLER, FEHLER ODER UNGENAUIGKEITEN VON INHALTEN UND MATERIALIEN, ( 2) PERSONEN- ODER SACHSCHÄDEN JEGLICHER ART, DIE SICH AUS IHREM ZUGRIFF AUF DIE WEBSITE UND IHRER NUTZUNG ERGEBEN, (3) JEGLICHEM UNBEFUGTEN ZUGRIFF AUF ODER NUTZUNG UNSERER SICHEREN SERVER UND/ODER ALLER PERSÖNLICHEN INFORMATIONEN UND/ODER FINANZINFORMATIONEN DARIN GESPEICHERT, (4) JEDE UNTERBRECHUNG ODER EINSTELLUNG DER ÜBERTRAGUNG ZU ODER VON DER
    SEITE, (5) JEGLICHE FEHLER, VIREN, TROJANISCHE PFERDE ODER ÄHNLICHES, DIE VON DRITTEN AUF ODER DURCH DIE SEITE ÜBERTRAGEN WERDEN KÖNNEN, UND/ODER (6) JEGLICHE FEHLER ODER AUSLASSUNGEN IN INHALTEN UND MATERIALIEN ODER FÜR JEGLICHEN VERLUST ODER SCHÄDEN JEGLICHER ART, DIE DURCH DIE VERWENDUNG VON INHALTEN ENTSTEHEN, DIE AUF DER WEBSITE GEPOSTET, ÜBERTRAGEN ODER ANDERWEITIG ZUR VERFÜGUNG GESTELLT WERDEN. WIR ÜBERNEHMEN KEINE GEWÄHRLEISTUNG, UNTERSTÜTZUNG, GARANTIE ODER VERANTWORTUNG FÜR PRODUKTE ODER DIENSTLEISTUNGEN, DIE VON EINEM DRITTEN ÜBER DIE WEBSITE, EINE HYPERVERLINKTE WEBSITE ODER EINE WEBSITE ODER MOBILE ANWENDUNG, DIE IN EINEM BANNER ODER EINER ANDEREN WERBUNG VORGESTELLT WIRD, BEWORBEN ODER ANGEBOTEN WERDEN, UND DAS WERDEN WIR NICHT EINE PARTEI SEIN ODER IN IRGENDEINER WEISE FÜR DIE ÜBERWACHUNG VON TRANSAKTIONEN ZWISCHEN IHNEN UND DRITTANBIETERN VON PRODUKTEN ODER DIENSTLEISTUNGEN VERANTWORTLICH SEIN. WIE BEIM KAUF EINES PRODUKTS ODER EINER DIENSTLEISTUNG ÜBER JEDES MEDIUM ODER IN JEDEM UMGEBUNG SOLLTEN SIE IHR BESTES URTEIL VERWENDEN UND GEGEBENENFALLS VORSICHTIG SEIN.
    <br><br>
    <strong>20. HAFTUNGSBESCHRÄNKUNGEN</strong>
    <br>
    IN KEINEM FALL HAFTEN WIR ODER UNSERE DIREKTOREN, MITARBEITER ODER VERTRETER IHNEN ODER DRITTEN GEGENÜBER FÜR DIREKTE, INDIREKTE, FOLGESCHÄDEN, BEISPIELHAFTE, ZUFÄLLIGE, SPEZIELLE ODER STRAFSCHÄDEN, EINSCHLIESSLICH ENTGANGENEM GEWINN, ENTGANGENEN EINNAHMEN, DATENVERLUST, ODER SONSTIGE SCHÄDEN, DIE DURCH IHRE NUTZUNG DER WEBSITE ENTSTEHEN, SELBST WENN WIR AUF DIE MÖGLICHKEIT SOLCHER SCHÄDEN HINGEWIESEN WURDEN.
    <br><br>
    <strong>21. SCHADENSERSATZ</strong>
    <br>
    Sie stimmen zu, uns zu verteidigen, zu entschädigen und schadlos zu halten, einschließlich unserer Tochtergesellschaften, verbundenen Unternehmen und aller unserer jeweiligen leitenden Angestellten, Vertreter, Partner und Mitarbeiter, von und gegen Verluste, Schäden, Haftungen, Ansprüche oder Forderungen, einschließlich angemessener Anwälte ' Gebühren und Ausgaben, die von Dritten aufgrund von oder aus Folgendem entstehen: (1) Nutzung der Website; (2) Verstoß gegen diese Nutzungsbedingungen; (3) jede Verletzung Ihrer Zusicherungen und Garantien, die in diesen Nutzungsbedingungen festgelegt sind; (4) Ihre Verletzung der Rechte Dritter, einschließlich, aber nicht beschränkt auf geistige Eigentumsrechte; oder (5) jede offenkundig schädliche Handlung gegenüber anderen Benutzern der Website, mit denen Sie über die Website verbunden sind. Ungeachtet des Vorstehenden behalten wir uns das Recht vor, auf Ihre Kosten die ausschließliche Verteidigung und Kontrolle jeglicher Angelegenheit zu übernehmenfür die Sie uns schadlos halten müssen, und Sie stimmen zu, auf Ihre Kosten bei der Abwehr solcher Ansprüche mit uns zusammenzuarbeiten. Wir werden angemessene Anstrengungen unternehmen, um Sie über solche Ansprüche, Maßnahmen oder
    Verfahren, das dieser Freistellung unterliegt, sobald er davon Kenntnis erlangt.
    <br><br>
    <strong>22. NUTZERDATEN</strong>
    <br>
    Wir speichern bestimmte Daten, die Sie an die Website übermitteln, um die Leistung der Website zu verwalten, sowie Daten, die sich auf Ihre Nutzung der Website beziehen. Obwohl wir regelmäßige routinemäßige Datensicherungen durchführen, sind Sie allein verantwortlich für alle Daten, die Sie übermitteln oder die sich auf Aktivitäten beziehen, die Sie bei der Nutzung der Website durchgeführt haben. Sie stimmen zu, dass wir Ihnen gegenüber nicht für den Verlust oder die Beschädigung solcher Daten haftbar sind, und Sie verzichten hiermit auf jegliche Klage gegen uns, die sich aus einem solchen Verlust oder einer solchen Beschädigung dieser Daten ergeben.
    <br><br>
    <strong>23. ELEKTRONISCHE KOMMUNIKATION, TRANSAKTIONEN UND UNTERSCHRIFTEN</strong>
    <br>
    Der Besuch der Website, das Senden von E-Mails an uns und das Ausfüllen von Online-Formularen stellen elektronische Kommunikation dar. Sie stimmen zu, elektronische Mitteilungen zu erhalten, und Sie stimmen zu, dass alle Vereinbarungen, Mitteilungen, Offenlegungen und andere Mitteilungen, die wir Ihnen elektronisch, per E-Mail und auf der Website zukommen lassen, alle gesetzlichen Anforderungen erfüllen, dass diese Mitteilungen schriftlich erfolgen müssen. SIE STIMMEN HIERMIT DER VERWENDUNG ELEKTRONISCHER UNTERSCHRIFTEN, VERTRÄGE, AUFTRÄGE UND ANDERER AUFZEICHNUNGEN SOWIE DER ELEKTRONISCHEN ZUSTELLUNG VON MITTEILUNGEN, RICHTLINIEN UND AUFZEICHNUNGEN VON TRANSAKTIONEN ZU, DIE VON UNS ODER ÜBER DIE WEBSITE EINGELEITET ODER ABGESCHLOSSEN WERDEN. Sie verzichten hiermit auf alle Rechte oder Anforderungen gemäß Gesetzen, Vorschriften, Regeln, Verordnungen oder anderen Gesetzen in einer Gerichtsbarkeit, die eine Originalunterschrift oder die Lieferung oder Aufbewahrung von nicht elektronischen Aufzeichnungen oder auf Zahlungen oder die Gewährung von Krediten auf andere Weise erfordern als auf elektronischem Weg.
    <br><br>
    <strong>24. VERSCHIEDENES</strong>
    <br>
    Diese Nutzungsbedingungen und alle Richtlinien oder Betriebsregeln, die von uns auf der Website oder in Bezug auf die Website veröffentlicht werden, stellen die gesamte Vereinbarung und Übereinkunft zwischen Ihnen und uns dar. Unser Versäumnis, ein Recht oder eine Bestimmung dieser Nutzungsbedingungen auszuüben oder durchzusetzen, gilt nicht als Verzicht auf dieses Recht oder diese Bestimmung. Diese Nutzungsbedingungen gelten im größtmöglichen gesetzlich zulässigen Umfang. Wir können unsere Rechte und Pflichten ganz oder teilweise jederzeit auf andere übertragen. Wir sind nicht verantwortlich oder haftbar für Verluste, Schäden, Verzögerungen oder Unterlassungen, die durch Ursachen verursacht werden, die außerhalb unserer angemessenen Kontrolle liegen. Wenn eine Bestimmung oder ein Teil einer Bestimmung dieser Nutzungsbedingungen als rechtswidrig, nichtig oder nicht durchsetzbar befunden wird, gilt diese Bestimmung oder ein Teil der Bestimmung als von diesen Nutzungsbedingungen trennbar und berührt nicht die Gültigkeit und Durchsetzbarkeit der verbleibenden Bestimmungen Bestimmungen. Aufgrund dieser Bedingungen entsteht zwischen Ihnen und uns kein Joint-Venture-, Partnerschafts-, Beschäftigungs- oder Vertretungsverhältnis
    Nutzung oder Nutzung der Website. Sie stimmen zu, dass diese Nutzungsbedingungen nicht gegen uns ausgelegt werden, da sie erstellt wurden. Sie verzichten hiermit auf alle Einwendungen, die Sie möglicherweise aufgrund der elektronischen Form dieser Nutzungsbedingungen und der fehlenden Unterzeichnung durch die Parteien zur Ausführung dieser Nutzungsbedingungen haben.
    <br><br>
    <strong>25. KONTAKTIEREN SIE UNS</strong>
    <br>
    
        Um eine Beschwerde bezüglich der Website zu lösen oder weitere Informationen zur Nutzung der Website zu erhalten, kontaktieren Sie uns bitte unter:
        <br><br>
        <b> M24 Labs GmbH
        <br>
        Leopoldstraße
        <br>
        München, Bayern 80804
        <br>
        Deutschland
        <br>
        Telefon: +49 172 9912415
        <br>
        support@{{environment.websiteUrl}}
    </b>
    </div>