<div class="terms">TERMENI ȘI CONDIȚII
    Ultima actualizare 10 martie 2022
    <br>
    <br><br>
    <strong>1. ACORD CU TERMENII</strong>
    <br>
    Acești Termeni de utilizare constituie un acord obligatoriu din punct de vedere juridic încheiat între dvs., fie personal, fie în numele unei entități („dvs.”) și M24 Labs GmH („Companie”, „noi”, „noi” sau „nostru”), cu privire la accesul dvs. la și utilizarea site-ului web https://peezzer.com, precum și orice alt formular media, canal media, site web mobil sau aplicație mobilă legată, legată sau conectată în alt mod la acesta (colectiv, „Site-ul”). Suntem înregistrați în Germania și avem sediul social la Leopoldstrasse, Munchen, Bavaria 80804. Numărul nostru de TVA este HRB 230202. Sunteți de acord că, accesând Site-ul, ați citit, înțeles și sunteți de acord să respectați toți acești Termeni și condiții. Utilizare. DACĂ NU SUNTEȚI DE ACORD CU TOȚI ACEȘTI TERMENI DE UTILIZARE, ATUNCI ȚI ESTE INTERZIS EXPRES DE A UTILIZA SITE-UL ȘI TREBUIE SĂ ÎNCEȘTEȚI UTILIZAREA Imediat.
    Termenii și condițiile suplimentare sau documentele care pot fi postate pe site din când în când sunt încorporate în mod expres aici prin referință. Ne rezervăm dreptul, la discreția noastră, de a face modificări sau modificări la acești Termeni de utilizare din când în când. Vă vom avertiza cu privire la orice modificare prin actualizarea datei „Ultima actualizare” a acestor Termeni de utilizare și renunțați la orice drept de a primi o notificare specifică cu privire la fiecare astfel de modificare. Vă rugăm să vă asigurați că verificați Termenii aplicabili de fiecare dată când utilizați Site-ul nostru, astfel încât să înțelegeți ce Termeni se aplică. Veți fi supus și veți fi considerat că ați luat cunoștință și că ați acceptat modificările oricăror Termeni de utilizare revizuiți prin continuarea utilizării site-ului după data la care sunt postați astfel de Termeni de utilizare revizuiți.
    Informațiile furnizate pe Site nu sunt destinate distribuirii sau utilizării de către nicio persoană sau entitate în nicio jurisdicție sau țară în care o astfel de distribuție sau utilizare ar fi contrară legii sau reglementărilor sau care ne-ar supune oricărei cerințe de înregistrare în această jurisdicție sau țară. . În consecință, acele persoane care aleg să acceseze Site-ul din alte locații fac acest lucru din proprie inițiativă și sunt singurele responsabile pentru conformitatea cu legile locale, dacă și în măsura în care legile locale sunt aplicabile.
    Site-ul este destinat utilizatorilor care au cel puțin 18 ani. Persoanelor cu vârsta sub 18 ani nu le este permis să utilizeze sau să se înregistreze pe Site.
    <br><br>
    <strong>2. DREPTURI DE PROPRIETE INTELECTUALĂ</strong>
    <br>
    Cu excepția cazului în care se indică altfel, Site-ul este proprietatea noastră deținută și toate codurile sursă, bazele de date, funcționalitățile, software-ul, design-urile site-ului web, audio, video, text, fotografii și grafice de pe Site (colectiv, „Conținutul”) și mărcile comerciale, serviciul mărcile și siglele conținute în acestea („Mărcile”) sunt deținute sau controlate de noi sau ne sunt licențiate și sunt protejate de legile privind drepturile de autor și mărcile comerciale și diverse alte drepturi de proprietate intelectuală și legile privind concurența neloială din Statele Unite, legile internaționale privind drepturile de autor, și convențiile internaționale. Conținutul și mărcile sunt furnizate pe site „CA AȘA ESTE” numai pentru informarea dumneavoastră și uz personal. Cu excepția cazurilor în care se prevede în mod expres în acești Termeni de utilizare, nicio parte a Site-ului și nicio parte a Site-ului și niciun Conținut sau Mărci nu poate fi copiată, reprodusă, agregată, republicată, încărcată, postată, afișată public, codificat, tradus, transmis, distribuit, vândut, licențiat sau exploatate altfel în orice scop comercial, fără prealabil nostru expres
    permisiunea scrisa.
    Cu condiția ca sunteți eligibil să utilizați Site-ul, vi se acordă o licență limitată pentru a accesa și utiliza Site-ul și pentru a descărca sau imprima o copie a oricărei părți a Conținutului la care ați obținut acces în mod corespunzător numai pentru dvs. personal, necomercial. utilizare. Ne rezervăm toate drepturile care nu vi s-au acordat în mod expres în și asupra Site-ului, conținutului și mărcilor.
    <br><br>
    <strong>3. DECLARAȚII UTILIZATOR</strong>
    <br>
    Prin utilizarea Site-ului, declarați și garantați că: (1) toate informațiile de înregistrare pe care le trimiteți vor fi adevărate, exacte, actuale și complete; (2) veți menține acuratețea acestor informații și veți actualiza prompt aceste informații de înregistrare, dacă este necesar; (3) aveți capacitatea juridică și sunteți de acord să respectați acești Termeni de utilizare; (4) nu sunteți minor în jurisdicția în care locuiți; (5) nu veți accesa Site-ul prin mijloace automate sau non-umane, fie printr-un bot, script sau altfel; (6) nu veți folosi Site-ul în niciun scop ilegal sau neautorizat; și (7) utilizarea de către dvs. a Site-ului nu va încălca nicio lege sau reglementare aplicabilă.
    Dacă furnizați orice informații care sunt neadevărate, inexacte, neactuale sau incomplete, avem dreptul de a suspenda sau de a închide contul dvs. și de a refuza orice utilizare curentă sau viitoare a Site-ului (sau a oricărei părți a acestuia).
    <br><br>
    <strong>4. ÎNREGISTRAREA UTILIZATOR</strong>
    <br>
    Este posibil să vi se solicite să vă înregistrați pe Site. Sunteți de acord să păstrați parola confidențială și veți fi responsabil pentru orice utilizare a contului și a parolei dvs. Ne rezervăm dreptul de a eliminae, revendicați sau modificați un nume de utilizator pe care îl selectați dacă stabilim, la discreția noastră, că un astfel de nume de utilizator este inadecvat, obscen sau inacceptabil în alt mod.
    <br><br>
    <strong>5. TAXE SI PLATA</strong>
    <br>
    Acceptăm următoarele forme de plată:
    - Viză
    - Mastercard
    - PayPal
    Este posibil să vi se solicite să cumpărați sau să plătiți o taxă pentru a accesa unele dintre serviciile noastre. Sunteți de acord să furnizați informații actuale, complete și exacte despre achiziție și cont pentru toate achizițiile efectuate prin intermediul Site-ului. În plus, sunteți de acord să actualizați prompt informațiile despre cont și de plată, inclusiv adresa de e-mail, metoda de plată și data de expirare a cardului de plată, astfel încât să vă putem finaliza tranzacțiile și să vă contactăm după cum este necesar. Vă facturăm printr-un cont de facturare online pentru achizițiile efectuate prin intermediul Site-ului. Taxa pe vânzări va fi adăugată la prețul achizițiilor, așa cum considerăm că este necesar. Putem schimba prețurile în orice moment. Toate plățile vor fi în euro.
    Sunteți de acord să plătiți toate taxele sau taxele la prețurile în vigoare atunci pentru achizițiile dvs. și ne autorizați să percepem furnizorul de plăți ales pentru orice astfel de sume la efectuarea achiziției. Dacă achiziția dvs. face obiectul unor taxe recurente, atunci sunteți de acord să vă debităm metoda de plată în mod recurent, fără a necesita aprobarea dumneavoastră prealabilă pentru fiecare taxă recurentă, până când ne notificați anularea dvs.
    Ne rezervăm dreptul de a corecta orice erori sau greșeli de preț, chiar dacă am solicitat sau am primit deja plata. De asemenea, ne rezervăm dreptul de a refuza orice comandă plasată prin intermediul Site-ului.
    <br><br>
    <strong>6. ANULAREA</strong>
    <br>
    Vă puteți anula oricând abonamentul, contactându-ne folosind informațiile de contact furnizate mai jos. Anularea dvs. va intra în vigoare la sfârșitul termenului curent plătit.
    Dacă nu sunteți mulțumit de serviciile noastre, vă rugăm să ne trimiteți un e-mail la support@peezzer.com sau să ne sunați la +49 172 9912415.
    <br><br>
    <strong>7. ACTIVITĂȚI INTERZISE</strong>
    <br>
    Nu puteți accesa sau utiliza Site-ul în alt scop decât cel pentru care punem la dispoziție Site-ul. Site-ul nu poate fi utilizat în legătură cu niciun demers comercial, cu excepția celor care sunt susținute sau aprobate în mod specific de noi.
    În calitate de utilizator al Site-ului, sunteți de acord să nu:
    * Preluați sistematic date sau alt conținut de pe Site pentru a crea sau compila, direct sau indirect, o colecție, o compilare, o bază de date sau un director fără permisiunea scrisă din partea noastră.
    * Înșelați, înșelați sau induceți în eroare pe noi și pe alți utilizatori, în special în orice încercare de a afla informații sensibile despre cont, cum ar fi parolele de utilizator.
    * Eluda, dezactivează sau interferează în alt mod cu caracteristicile legate de securitate ale Site-ului, inclusiv caracteristicile care împiedică sau restricționează utilizarea sau copierea oricărui Conținut sau impun limitări privind utilizarea Site-ului și/sau a Conținutului conținut în acesta.
    * Dezamăgiți, pătați sau răniți în alt mod, în opinia noastră, nouă și/sau Site-ului.
    * Utilizați orice informație obținută de pe site pentru a hărțui, a abuza sau a răni o altă persoană.
    * Utilizați necorespunzător serviciile noastre de asistență sau trimiteți rapoarte false de abuz sau conduită greșită.
    * Utilizați Site-ul într-o manieră neconformă cu orice legi sau reglementări aplicabile.
    * Angajați-vă în încadrarea neautorizată sau conectarea către Site.
    * Încărcați sau transmiteți (sau încercați să încărcați sau să transmiteți) viruși, cai troieni sau alte materiale, inclusiv utilizarea excesivă a majusculelor și spam-ul (postarea continuă a textului repetitiv), care interferează cu utilizarea neîntreruptă și bucuria de către orice parte a Site-ului sau modifică, deteriorează, perturbă, alterează sau interferează cu utilizarea, caracteristicile, funcțiile, operarea sau întreținerea Site-ului.
    * Angajați-vă în orice utilizare automată a sistemului, cum ar fi utilizarea scripturilor pentru a trimite comentarii sau mesaje sau folosirea oricăror instrumente de extragere și extragere a datelor, roboți sau alte instrumente similare.
    * Ștergeți notificarea privind drepturile de autor sau alte drepturi de proprietate din orice Conținut.
    * Încercați să vă uzurpați identitatea unui alt utilizator sau persoană sau să utilizați numele de utilizator al altui utilizator.
    * Încărcați sau transmiteți (sau încercați să încărcați sau să transmiteți) orice material care acționează ca un mecanism pasiv sau activ de colectare sau transmitere a informațiilor, inclusiv, fără limitare, formate de schimb de grafice clare („gif”), pixeli 1×1, bug-uri web, cookie-uri sau alte dispozitive similare (uneori denumite „spyware” sau „mecanisme pasive de colectare” sau „pcm-uri”).
    * interferează cu, perturbă sau creează o povară nejustificată asupra Site-ului sau a rețelelor sau serviciilor conectate la Site.
    * Hărțuiți, enervați, intimidați sau amenințați pe oricare dintre angajații sau agenții noștri implicați în a vă furniza orice parte a Site-ului.
    * Încercați să ocoliți orice măsuri ale Site-ului menite să împiedice sau să restricționeze accesul la Site sau la orice parte a Site-ului.
    * Copiați sau adaptați software-ul Site-ului, inclusiv, dar fără a se limita la, Flash, PHP, HTML, JavaScript sau alt cod.
    * Cu excepția cazurilor permise de legea aplicabilă, descifrați, decompilați, dezasamblați sau efectuați inginerie inversă a oricărui software care cuprinde sau alcătuiește în orice fel o parte a Site-ului.
    * Cu excepția cazului în care poate fi rezultatul standarduluid utilizarea, utilizarea, lansarea, dezvoltarea sau distribuirea unui motor de căutare sau browser de Internet a oricărui sistem automatizat, inclusiv, fără limitare, a oricărui păianjen, robot, utilitar de trișare, scraper sau cititor offline care accesează Site-ul sau utilizând sau lansând orice script neautorizat sau alt software.
    * Folosiți un agent de cumpărare sau un agent de cumpărare pentru a face achiziții pe Site.
    * Faceți orice utilizare neautorizată a Site-ului, inclusiv colectarea numelor de utilizator și/sau a adreselor de e-mail ale utilizatorilor prin mijloace electronice sau prin alte mijloace în scopul trimiterii de e-mailuri nesolicitate sau crearea de conturi de utilizator prin mijloace automate sau sub pretexte false.
    * Utilizați Site-ul ca parte a oricărui efort de a concura cu noi sau utilizați în alt mod Site-ul și/sau Conținutul pentru orice efort generator de venituri sau întreprindere comercială.
    <br><br>
    <strong>8. CONTRIBUȚII GENERATE DE UTILIZATOR</strong>
    <br>
    Site-ul nu oferă utilizatorilor să trimită sau să posteze conținut. Vă putem oferi posibilitatea de a crea, trimite, posta, afișa, transmite, executa, publica, distribui sau difuza conținut și materiale către noi sau pe site, inclusiv, dar fără a se limita la text, scrieri, video, audio, fotografii , grafice, comentarii, sugestii sau informații personale sau alte materiale (colectiv, „Contribuții”). Contribuțiile pot fi vizualizate de către alți utilizatori ai Site-ului și prin intermediul site-urilor web ale terților. Ca atare, orice Contribuții pe care le transmiteți pot fi tratate în conformitate cu Politica de confidențialitate a site-ului. Când creați sau puneți la dispoziție orice Contribuții, declarați și garantați că:
    * Crearea, distribuirea, transmiterea, afișarea publică sau performanța și accesarea, descărcarea sau copierea Contribuțiilor dvs. nu încalcă și nu vor încălca drepturile de proprietate, inclusiv, dar fără a se limita la, dreptul de autor, brevetul, marca comercială, secretul comercial, sau drepturile morale ale oricărei terțe părți.
    * Sunteți creatorul și proprietarul sau aveți licențele, drepturile, acordurile, eliberările și permisiunile necesare pentru a folosi și a autoriza pe noi, pe site-ul și pe alți utilizatori ai site-ului să vă folosim Contribuțiile în orice mod prevăzut de site și acești Termeni de utilizare.
    * Aveți consimțământul scris, eliberarea și/sau permisiunea fiecărei persoane individuale identificabile din Contribuțiile dvs. de a utiliza numele sau asemănarea fiecărei persoane individuale identificabile pentru a permite includerea și utilizarea Contribuțiilor dvs. în orice mod prevăzut de Site-ul și acești Termeni de utilizare.
    * Contribuțiile dvs. nu sunt false, inexacte sau înșelătoare.
    * Contribuțiile dvs. nu sunt publicitate nesolicitată sau neautorizată, materiale promoționale, scheme piramidale, scrisori în lanț, spam, e-mailuri în masă sau alte forme de solicitare.
    * Contribuțiile dvs. nu sunt obscene, lascive, lascive, murdare, violente, hărțuitoare, calomnioase, calomnioase sau în orice alt mod inacceptabile (așa cum am stabilit de noi).
    * Contribuțiile dvs. nu ridiculizează, batjocoresc, denigrează, intimidează sau abuzează pe nimeni.
    * Contribuțiile dvs. nu sunt folosite pentru a hărțui sau a amenința (în sensul legal al acelor termeni) orice altă persoană și pentru a promova violența împotriva unei anumite persoane sau clase de persoane.
    * Contribuțiile dvs. nu încalcă nicio lege, regulament sau regulă aplicabilă.
    * Contribuțiile dvs. nu încalcă drepturile de confidențialitate sau publicitate ale niciunei terțe părți.
    * Contribuțiile dvs. nu încalcă nicio lege aplicabilă referitoare la pornografia infantilă și nici nu au scopul de a proteja sănătatea sau bunăstarea minorilor.
    * Contribuțiile dvs. nu includ comentarii jignitoare care sunt legate de rasă, origine națională, sex, preferințe sexuale sau handicap fizic.
    * Contribuțiile dvs. nu încalcă în alt mod, și nu leagă la material care încalcă, nicio prevedere a acestor Termeni de utilizare sau orice lege sau reglementare aplicabilă.
    
    Orice utilizare a Site-ului cu încălcarea celor de mai sus încalcă acești Termeni de utilizare și poate duce, printre altele, la rezilierea sau suspendarea drepturilor dumneavoastră de a utiliza Site-ul.
    <br><br>
    <strong>9. LICENȚĂ DE CONTRIBUȚIE</strong>
    <br>
    Dumneavoastră și site-ul sunteți de acord că putem accesa, stoca, procesa și utiliza orice informație și date personale pe care le furnizați în conformitate cu termenii Politicii de confidențialitate și alegerile dvs. (inclusiv setările).
    
    Prin trimiterea de sugestii sau alte feedback-uri cu privire la Site, sunteți de acord că putem folosi și împărtăși astfel de feedback în orice scop, fără a vă compensa.
    
    Nu afirmăm nicio proprietate asupra Contribuțiilor dvs. Vă păstrați proprietatea deplină asupra tuturor Contribuțiilor dvs. și a oricăror drepturi de proprietate intelectuală sau alte drepturi de proprietate asociate cu Contribuțiile dvs. Nu suntem răspunzători pentru nicio declarație sau declarație din Contribuțiile dvs. furnizate de dvs. în orice zonă de pe Site. Sunteți singurul responsabil pentru Contribuțiile dumneavoastră la Site și sunteți de acord în mod expres să ne exonerați de orice responsabilitate și să vă abțineți de la orice acțiune legală împotriva noastră cu privire la Contribuțiile dumneavoastră.
    <br><br>
    <strong>10. DEMISĂRI</strong>
    <br>
    Recunoașteți și sunteți de acord că orice întrebări, comentarii, sugestii, idei, feedback sau alte informații privescUtilizarea site-ului („Trimiterile”) furnizate de dvs. către noi sunt neconfidențiale și vor deveni proprietatea noastră exclusivă. Vom deține drepturi exclusive, inclusiv toate drepturile de proprietate intelectuală și vom avea dreptul la utilizarea și diseminarea fără restricții a acestor Trimiteri în orice scop legal, comercial sau de altă natură, fără recunoaștere sau despăgubire pentru dvs. Prin prezenta renunțați la toate drepturile morale asupra oricăror astfel de Trimiteri și garantați prin prezenta că orice astfel de Trimiteri sunt originale cu dvs. sau că aveți dreptul de a trimite astfel de Trimiteri. Sunteți de acord că nu va exista niciun recurs împotriva noastră pentru orice presupusă sau reală încălcare sau deturnare a oricărui drept de proprietate asupra Trimiterilor dumneavoastră.
    <br><br>
    <strong>11. GESTIONAREA SITE-ULUI</strong>
    <br>
    Ne rezervăm dreptul, dar nu și obligația, de a: (1) monitoriza Site-ul pentru încălcări ale acestor Termeni de utilizare; (2) să ia măsurile legale corespunzătoare împotriva oricărei persoane care, la discreția noastră, încalcă legea sau acești Termeni de utilizare, inclusiv, fără limitare, raportarea unui astfel de utilizator autorităților de aplicare a legii; (3) la discreția noastră exclusivă și fără limitare, refuzați, restricționați accesul la, limitați disponibilitatea sau dezactivați (în măsura în care este posibil din punct de vedere tehnologic) oricare dintre Contribuțiile dvs. sau orice parte a acestora; (4) la discreția noastră exclusivă și fără limitare, notificare sau răspundere, să eliminam de pe Site sau să dezactivăm în orice alt mod toate fișierele și conținutul care au dimensiuni excesive sau sunt în vreun fel împovărătoare pentru sistemele noastre; și (5) să gestioneze în alt mod Site-ul într-o manieră concepută pentru a ne proteja drepturile și proprietatea și pentru a facilita funcționarea corectă a Site-ului.
    <br><br>
    <strong>12. POLITICA DE CONFIDENTIALITATE</strong>
    <br>
    Ne pasă de confidențialitatea și securitatea datelor. Prin utilizarea Site-ului, sunteți de acord să fiți legat de Politica noastră de confidențialitate postată pe Site, care este încorporată în acești Termeni de utilizare. Vă rugăm să rețineți că site-ul este găzduit
    Germania. Dacă accesați Site-ul din orice altă regiune a lumii cu legi sau alte cerințe care reglementează colectarea, utilizarea sau dezvăluirea datelor cu caracter personal care diferă de legile aplicabile în Germania, atunci prin utilizarea continuă a Site-ului, vă transferați datele în Germania. , și sunteți de acord ca datele dumneavoastră să fie transferate și prelucrate în Germania.
    <br><br>
    <strong>13. ÎNCĂLCAREA DREPTURILOR DE AUTOR</strong>
    <br>
    Respectăm drepturile de proprietate intelectuală ale altora. Dacă considerați că orice material disponibil pe sau prin intermediul Site-ului încalcă orice drept de autor pe care îl dețineți sau îl controlați, vă rugăm să ne anunțați imediat folosind informațiile de contact furnizate mai jos (o „Notificare”). O copie a notificării dumneavoastră va fi trimisă persoanei care a postat sau stocat materialul adresat în notificare. Vă rugăm să fiți informat că, în conformitate cu legislația în vigoare, puteți fi tras la răspundere pentru daune dacă faceți declarații greșite semnificative într-o Notificare. Astfel, dacă nu sunteți sigur că materialul aflat pe site sau legat de acesta vă încalcă drepturile de autor, ar trebui să luați în considerare mai întâi să contactați un avocat.
    <br><br>
    <strong>14. DURĂ ȘI ÎNCHEIAȚIE</strong>
    <br>
    Acești Termeni de utilizare vor rămâne în vigoare și în vigoare în timp ce utilizați Site-ul. FĂRĂ A LIMITA ORICE ALTĂ PREVEDERE A ACEȘTI TERMENI DE UTILIZARE, NE REZERVĂM DREPTUL DE A, ÎN UNICA NOASTRĂ DISCREȚIE ȘI FĂRĂ PREVIZARE SAU RESPONSABILITATE, A RESPAZĂ ACCESUL ȘI UTILIZAREA SITE-ULUI (INCLUSIV BLOCAREA ANUMITE ADRESE IP), ORICE PERSOANE PENTRU ORICE PERSOANE FĂRĂ NICIUN MOTIVE, INCLUSIV, FĂRĂ LIMITAȚII, ÎNCĂLCAREA ORICE DECLARAȚII, GARANȚII SAU CONVENȚII CONȚINUTE ÎN ACEȘTI TERMENI DE UTILIZARE SAU A ORICE LEGĂ SAU REGLAMENT APLICABLE. PUTEM TERMINA UTILIZAREA SAU PARTICIPAREA DVS. LA SITE SAU ȘTERGEREA DVS. ȘI ORICE CONȚINUT SAU INFORMAȚIE PE CARE LE-ați POSTAT ÎN ORICE MOMENT, FĂRĂ AVERTISMENT, LA DISCREȚIA NOASTRĂ.
    
    Dacă vă închidem sau suspendăm contul din orice motiv, vi se interzice să vă înregistrați și să creați un nou cont sub numele dvs., un nume fals sau împrumutat sau numele oricărei terțe părți, chiar dacă ați acționa în numele unei terțe părți. parte. Pe lângă încheierea sau suspendarea contului dvs., ne rezervăm dreptul de a lua măsurile legale corespunzătoare, inclusiv, fără limitare, urmărirea despăgubirilor civile, penale și prin injoncțiune.
    <br><br>
    <strong>15. MODIFICĂRI ȘI ÎNTRERUPĂRI</strong>
    <br>
    Ne rezervăm dreptul de a schimba, modifica sau elimina conținutul Site-ului în orice moment sau din orice motiv, la discreția noastră, fără notificare. Cu toate acestea, nu avem nicio obligație de a actualiza orice informații de pe site-ul nostru. De asemenea, ne rezervăm dreptul de a modifica sau întrerupe tot sau o parte a Site-ului fără notificare în orice moment. Nu vom fi răspunzători față de dvs. sau față de orice terță parte pentru orice modificare, modificare a prețului, suspendare sau întrerupere a Site-ului.
    
    Nu putem garanta că Site-ul va fi disponibil în orice moment. Este posibil să întâmpinăm probleme hardware, software sau alte probleme sau să fie nevoie să efectuăm întreținere legate de Site, ceea ce duce la întreruperi, întârzieri sau erori. Ne rezervăm dreptul de a schimba, revizui, actualiza, suspenda, întrerupe sau modifica în alt mod Site-ul în orice moment.imediat sau din orice motiv, fără notificare. Sunteți de acord că nu avem nicio răspundere pentru orice pierdere, daune sau inconvenient cauzat de incapacitatea dumneavoastră de a accesa sau de a utiliza Site-ul în timpul oricărei perioade de nefuncționare sau întrerupere a Site-ului. Nimic din acești Termeni de utilizare nu va fi interpretat ca ne obligă să menținem și să sprijinim Site-ul sau să furnizăm orice corecții, actualizări sau versiuni în legătură cu acestea.
    <br><br>
    <strong>16. LEGEA APLICATĂ</strong>
    <br>
    Aceste condiții sunt guvernate și interpretate în conformitate cu legile Germaniei, iar utilizarea Convenției Națiunilor Unite privind contractele pentru vânzarea internațională de bunuri este exclusă în mod expres. Dacă reședința dvs. obișnuită este în UE și sunteți consumator, aveți în plus și protecția oferită de dispozițiile obligatorii ale legii țării dvs. de reședință. M24 Labs GmH și dumneavoastră sunteți de acord să vă supuneți jurisdicției neexclusive a instanțelor din München, ceea ce înseamnă că puteți face o cerere pentru a vă apăra drepturile de protecție a consumatorilor în ceea ce privește aceste Condiții de utilizare în Germania sau în țara UE in care locuiti.
    <br><br>
    <strong>17. SOLUȚIONAREA LITIGIEI</strong>
    <br>
    Comisia Europeană oferă o platformă online de soluționare a litigiilor, pe care o puteți accesa aici: https://ec.europa.eu/consumers/odr. Dacă doriți să ne aduceți la cunoștință acest subiect, vă rugăm să ne contactați.
    <br><br>
    <strong>18. CORECTII</strong>
    <br>
    Pot exista informații pe Site care să conțină erori de tipar, inexactități sau omisiuni, inclusiv descrieri, prețuri, disponibilitate și diverse alte informații. Ne rezervăm dreptul de a corecta orice erori, inexactități sau omisiuni și de a modifica sau actualiza informațiile de pe Site în orice moment, fără notificare prealabilă.
    <br><br>
    <strong>19. DISCLAIMER</strong>
    <br>
    SITE-UL ESTE FURNIZAT CA ASA ȘI DISPONIBIL. SUNTEȚI DE ACORD CĂ UTILIZAREA SITE-ULUI ȘI A SERVICIILOR NOASTRE VA FI PE UNCUL RISC. ÎN MĂSURA TOTALĂ PERMISĂ DE LEGE, RENUNȚĂM LA TOATE GARANȚII, EXPRESE SAU IMPLICITE, ÎN LEGAȚIE CU SITE-UL ȘI UTILIZAREA ACESTORA DE DVS., INCLUSIV, FĂRĂ LIMITAȚII, GARANȚII IMPLICITE DE COMERCIABILITATE, ADECVENȚĂ PENTRU PARTICIPARE ȘI IMPLICITATE PENTRU PARTICIPARE. NU OFERIM NU GARANTIEM SAU DECLARAȚII CU PRIVIRE LA ACURATEȚIA SAU COMPLETEZA CONȚINUTULUI SITE-ULUI SAU CONȚINUTUL ORICORUI SITE-URI WEB LEGATE LA SITE ȘI NU NE VĂ ASUMĂ NICIO RESPONSABILITATE SAU RESPONSABILITATE PENTRU ORICE (1) ERORI, GREȘELI ȘI ERORI DE CONTACTE 2) RĂNIREA PERSONALĂ SAU DAUNEA PROPRIETĂȚII, DE ORICARE NATURĂ, REZULTATE DIN ACCESUL DVS. ȘI UTILIZAREA SITE-ULUI, (3) ORICE ACCES NEAUTORIZAT SAU UTILIZARE A SERVERELE NOASTRE SECURITE ȘI/SAU TOATE INFORMAȚII PERSONALE ȘI/SAU INFORMAȚII FINANCIARE STOCATE ÎN ACEST, (4) ORICE ÎNTRERUPERE SAU ÎNCETAREA TRANSMISIEI LA SAU DE LA
    SITE, (5) ORICE EROARE, VIRUS, CAI TROIENI SAU CARE POT FI TRANSMIS LA SAU PRIN SITE DE ORICE TERȚ ȘI/SAU (6) ORICE ERORI SAU OMISIUNE ÎN ORICE CONȚINUT ȘI MATERIALE SAU PENTRU ORICE PIERDERI DAUNE DE ORICE FEL SUPURITE CA REZULTAT UTILIZĂRII ORICĂRUI CONȚINUT POSTAT, TRANSMIS SAU PUBLAT DISPONIBIL ÎN ALTRE MOD PRIN SITE. NU GARANTĂM, GARANTĂM, NU GARANTĂM ȘI NU ASUMĂM RESPONSABILITATE PENTRU NICIO PRODUS SAU SERVICIU PUBLICIT SAU OFERIT DE O TERȚĂ PRIN SITE, ORICE SITE WEB HIPERLEGAT SAU ORICE SITE WEB SAU APLICAȚIE MOBILĂ ÎN APLICAȚIE ȘI APLICAȚIE MOBILĂ, NU FACEȚI APLICAȚIE ȘI APLICAȚIE MOBILĂ. FIȚI PARTE A SAU FI ÎN ORICE CEL RESPONSABIL PENTRU MONITORIZAREA ORICE TRANZACȚIE ÎNTRE DVS. ȘI ORICE TERȚI FURNITORI DE PRODUSE SAU SERVICII. CA CU CUMPĂRÂREA UNUI PRODUS SAU SERVICIU PRIN ORICE MEDIU SAU ÎN ORICE MEDIU, TREBUIE SĂ UTILIZAȚI CELE MAI BUNĂ RAȚIUNE ȘI SĂ FACEȚI ATENȚIE UNDE ESTE NECESAR.
    <br><br>
    <strong>20. LIMITĂRI DE RĂSPUNDERE</strong>
    <br>
    NICIUN CAZ NOI SAU DIRECTORII, ANGAJATII SAU AGENȚII NOȘTRI NU VOM FI RESPONSABIL ÎN FAȚA DE DVS. SAU ORICE ORICE TERȚ PENTRU ORICE DAUNE DIRECTE, INDIRECTE, CONSECUȚIONALE, EXEMPLARE, INCIDENTALE, SPECIALE SAU PUNITIVE, INCLUSIV PIERDERE DE PROFIT, PIERDERE DE PROFIT, PIERDERE DE VENITURI SAU ALTE PREJUDICII DERIVATE DIN UTILIZAREA SITE-ULUI DE CĂTRE DVS. CHIAR DACĂ AM FOST INFORMAȚI CU POSIBILITATEA ASTELOR DAUNE.
    <br><br>
    <strong>21. DESPĂGUBIRE</strong>
    <br>
    Sunteți de acord să ne apărați, să ne despăgubiți și să ne faceți inofensivi, inclusiv filialele noastre, afiliații și toți ofițerii, agenții, partenerii și angajații noștri respectivi, de și împotriva oricărei pierderi, daune, răspundere, revendicare sau cerere, inclusiv avocați rezonabili. ' taxele și cheltuielile, efectuate de orice terță parte ca urmare a sau care decurg din: (1) utilizarea Site-ului; (2) încălcarea acestor Termeni de utilizare; (3) orice încălcare a declarațiilor și garanțiilor dumneavoastră prevăzute în acești Termeni de utilizare; (4) încălcarea de către dumneavoastră a drepturilor unei terțe părți, inclusiv, dar fără a se limita la, drepturile de proprietate intelectuală; sau (5) orice act vătămător evident față de orice alt utilizator al Site-ului cu care v-ați conectat prin intermediul Site-ului. Fără a aduce atingere celor de mai sus, ne rezervăm dreptul, pe cheltuiala dumneavoastră, de a ne asuma apărarea și controlul exclusiv asupra oricărei probleme.pentru care vi se cere să ne despăgubiți și sunteți de acord să cooperați, pe cheltuiala dumneavoastră, cu apărarea noastră a unor astfel de revendicări. Vom depune eforturi rezonabile pentru a vă notifica cu privire la orice astfel de reclamație, acțiune sau
    procedura care face obiectul acestei despăgubiri la cunoștința acesteia.
    <br><br>
    <strong>22. DATE UTILIZATOR</strong>
    <br>
    Vom păstra anumite date pe care le transmiteți către Site în scopul gestionării performanței Site-ului, precum și date referitoare la utilizarea de către dvs. a Site-ului. Deși efectuăm copii de rezervă regulate de rutină ale datelor, sunteți singurul responsabil pentru toate datele pe care le transmiteți sau care se referă la orice activitate pe care ați întreprins-o folosind Site-ul. Sunteți de acord că nu vom avea nicio răspundere față de dvs. pentru pierderea sau coruperea oricăror astfel de date și, prin prezenta, renunțați la orice drept de acțiune împotriva noastră care decurge din orice astfel de pierdere sau corupție a acestor date.
    <br><br>
    <strong>23. COMUNICAȚII ELECTRONICE, TRANZACȚII ȘI SEMĂTURI</strong>
    <br>
    Vizitarea Site-ului, trimiterea de e-mailuri și completarea formularelor online constituie comunicări electronice. Sunteți de acord să primiți comunicări electronice și sunteți de acord că toate acordurile, notificările, dezvăluirile și alte comunicări pe care vi le furnizăm electronic, prin e-mail și pe Site, îndeplinesc orice cerință legală ca o astfel de comunicare să fie în scris. SUNTEȚI DE ACORD CU UTILIZAREA SEMNATURĂRILOR ELECTRONICE, CONTRACTELOR, COMENZILOR ȘI A ALTE ÎNREGISTRĂRI ȘI CU LIVRAREA ELECTRONICĂ A OBSERVAȚILOR, POLITICILOR ȘI ÎNREGISTRĂRILOR TRANZACȚIUNILOR INIȚIE SAU FINALIZATE DE NOI SAU PRIN SITE. Renunțați prin prezenta la orice drept sau cerințe în temeiul oricăror statut, reglementări, reguli, ordonanțe sau alte legi din orice jurisdicție care necesită o semnătură originală sau livrarea sau păstrarea înregistrărilor neelectronice sau la plăți sau acordarea de credite prin orice alt mijloc. decât mijloacele electronice.
    <br><br>
    <strong>24. DIVERSE</strong>
    <br>
    Acești Termeni de utilizare și orice politici sau reguli de operare postate de noi pe Site sau cu privire la Site constituie întregul acord și înțelegere dintre dumneavoastră și noi. Eșecul nostru de a exercita sau de a aplica orice drept sau prevedere a acestor Termeni de utilizare nu va funcționa ca o renunțare la un astfel de drept sau prevedere. Acești Termeni de utilizare funcționează în cea mai mare măsură permisă de lege. Putem atribui oricare sau toate drepturile și obligațiile noastre altora în orice moment. Nu vom fi responsabili sau răspunzători pentru nicio pierdere, daune, întârziere sau eșec de a acționa cauzate de orice cauză care nu este controlată de noi în mod rezonabil. Dacă orice prevedere sau parte a unei prevederi a acestor Termeni de utilizare este determinată a fi ilegală, nulă sau inaplicabilă, acea prevedere sau o parte a prevederii este considerată separabilă de acești Termeni de utilizare și nu afectează valabilitatea și caracterul executoriu al oricărei prevederi rămase. prevederi. Nu există nicio societate în comun, parteneriat, angajare sau relație de agenție creată între dvs. și noi ca urmare a acestor Termeni
    de Utilizare sau utilizare a Site-ului. Sunteți de acord că acești Termeni de utilizare nu vor fi interpretați împotriva noastră în virtutea redactării lor. Prin prezenta, renunțați la orice apărare pe care le puteți avea pe baza formei electronice a acestor Termeni de utilizare și a lipsei semnării de către părțile contractante pentru a executa acești Termeni de utilizare.
    <br><br>
    <strong>25. CONTACTAȚI-NE</strong>
    <br>
    
        Pentru a rezolva o reclamație referitoare la Site sau pentru a primi informații suplimentare cu privire la utilizarea Site-ului, vă rugăm să ne contactați la:
        <br><br>
        <b> M24 Labs GmH
        <br>
        Leopoldstrasse
        <br>
        Munchen, Bavaria 80804
        <br>
        Germania
        <br>
        Telefon: +49 172 9912415
        <br>
        support@peezer.com
    </b>
    </div>
