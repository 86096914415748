<div>Form Builder Works!</div>

<div id ='buildedForm'>
  <form [formGroup]='buildedForm' (ngSumbit)="submit()">
      <div *ngFor="let field of formFields">
          <app-form-fields [buildedForm]='buildedForm' [formField]=field></app-form-fields>
      </div>
      <div class='col-lg-12'>
        <span (click)="submit()" style="float:right;" >Save</span>
      </div>
  </form>
</div>
