<div class="container-xxl">
  <div class="topbar text-center bg-gray-100" *ngIf="topBarDisplay">
    <span *ngIf="daysToExpire > 0; else expired"> {{'payment.subscription_expires' | translate: {value : daysToExpire} }} </span>
    <ng-template #expired>
        <span >{{'payment.subscription_expired' | translate}} </span> 
    </ng-template>
    <span><b><a routerLink="/payment/pay"> Renew Subscription</a></b></span>

  </div>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand">

        <img src="../../../assets/images/logo-horisontal.png" width="280" alt="" class="d-inline-block align-middle mr-2">
      </a>
      <div class="d-flex justify-content-end buttons-container">
          <div class="new-menu">     
              <div  *ngIf="theme" class="new-menu-item" id="stepThemeSelector">
                <div class="oval" [ngClass]="{ovalSelected: theme === 'bootstrap'}" (click)="toggleTheme()">
                    <div class="button" [ngClass]="{buttonSelected: theme === 'bootstrap'}"></div>
                </div>
              </div>
              <div *ngIf="lang" class="new-menu-item"  id="stepLanguageSelector">
                <div class="dropdown">
                    <button class="dropbtn bg-light">
                        <img  src="../../../assets/flags/{{lang}}.png" width="28" height="20">
                    </button>
                      
                    <div class="dropdown-content bg-light" >
                        <span *ngFor="let language of environment.languages">
                            <img *ngIf="language!==lang" src="../../../assets/flags/{{language}}.png" width="28" height="20" class="flag-image" (click)="switchLang(language)"></span>
          
                    </div>
                </div>
              </div>
              <div class="new-menu-item" >
                <li class="nav-item dropdown hamburger app-menu" id="stepNavMenu">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="navbarDropdown" >
                            <li><strong>{{'menu.contacts'|translate}}</strong>
                                <ul id="stepClientsMenu">
                                  <li><a class="menu__item" routerLink="/clients/add" [routerLinkActive]="'is-active'">{{'menu.new_contact'|translate}}</a></li>
                                  <li><a class="menu__item" routerLink="/clients/all" [routerLinkActive]="'is-active'">{{'menu.contacts'|translate}}</a></li>
                                </ul>
                              </li>
                              <li><hr class="dropdown-divider"></li>
                              <li><strong>{{'menu.invoices'|translate}}</strong>
                                <ul>
                                  <li><a class="menu__item" routerLink="/invoices/add" [routerLinkActive]="'is-active'">{{'menu.new_invoice'|translate}}</a></li>
                                  <li><a class="menu__item" routerLink="/invoices/list/all" [routerLinkActive]="'is-active'">{{'menu.outgoing_invoices'|translate}}</a></li>
                                  <li><a class="menu__item" routerLink="/invoices/invoices-in-list" [routerLinkActive]="'is-active'">{{'menu.incoming_invoices'|translate}}</a></li>
                                  <li><a class="menu__item" routerLink="/invoices/statistics" [routerLinkActive]="'is-active'">{{'menu.analytics'|translate}}</a></li>
                                  <li><a class="menu__item" routerLink="/invoices/settings" [routerLinkActive]="'is-active'">{{'menu.settings'|translate}}</a></li>
                                </ul>
                              </li>
                              
                                <li *ngIf="environment.siteName ==='Peezzer'"><strong>{{'menu.website'|translate}}</strong>
                                  <ul>
                                    <li><hr class="dropdown-divider"></li>
                                    <li><a class="menu__item" routerLink="/webpage" [queryParams]="{tab: 'settings'}" [routerLinkActive]="'is-active'">{{'menu.settings_and_pages'|translate}}</a></li>
                                    <li><a class="menu__item" routerLink="/domain-settings" [routerLinkActive]="'is-active'">{{'menu.dns_settings'|translate}}</a></li>
                                  </ul>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                              <li><a class="menu__item" routerLink="/profile" [routerLinkActive]="'is-active'">{{'menu.my_profile'|translate}}</a></li>
                              <li (click)=logout()><a class="menu__item" routerLink="">{{'menu.logout'|translate}}</a></li>
                    </ul>
                  </li>
              </div>
              <div class="new-menu-item">
                <ng-template #userDetailsContent class="popovercontent">
                    <span *ngIf="userDetails.firstname!=''&&userDetails.lastname!=''"> {{'global.name' | translate}}: {{userDetails.firstname}} {{userDetails.lastname}}<br></span>
                    <span *ngIf="userDetails.email"> {{'global.email' | translate}}: {{userDetails.email}}<br></span>
                    <span *ngIf="userDetails.company!=''"> {{'global.company' | translate}}: {{userDetails.company}}<br></span>
                    <a routerLink="/profile" class="btn btn-info btn-sm mt-3 btn-block"><i class="fa fa-user" aria-hidden="true"></i> {{'menu.my_profile'|translate}}</a>
                    <span (click)=logout() class="btn btn-secondary btn-sm mt-2 btn-block"><i class="fa fa-sign-out" aria-hidden="true"></i> {{'menu.logout'|translate}}</span>
                </ng-template>
                <div class="d-flex menu-container m-1" >
                  <div class="user-profile align-middle" [ngbPopover]="userDetailsContent"  popoverTitle="User Info" ><span *ngIf="userDetails['email']" class="align-middle user-initials">{{getUserInitials()}}</span></div>
            </div>
              </div>
          </div>

    </div>
    </div>
  </nav>
</div>
