import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Contact, User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Contact[]>(`${environment.apiUrl}/item/contact`);
    }

    getById(id: number) {
        return this.http.get<User>(`${environment.apiUrl}/item/contact`);
    }

    register(user: User) {
      return this.http.post(`${environment.apiUrl}/item/contact`, user);
    }

    delete(id: number) {
      return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }

    sendInvitation(emailInvite) {
      return this.http.post(`${environment.apiUrl}/users/invite`, emailInvite);
    }
    passResetRequest(email){
      return this.http.get(`${environment.apiUrl}/passreset?email=${email}`);
    }
    passReset(body){
      return this.http.post(`${environment.apiUrl}/passreset`, body);
    }
    userActivate(body){
      return this.http.post(`${environment.apiUrl}/useractivate`, body);
    }
    userSendActivate(body){
        return this.http.post(`${environment.apiUrl}/usersendactivate`, body);
    }
}
