import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, ItemService } from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    loading = false;
    profileSubmitted = false;
    passSubmitted = false;
    profileForm: FormGroup;
    changePasswdForm: FormGroup;
    allCountries = [];
    countries = [];
    countryIdPlaceholder = '';
    imageSrc = '../../../assets/images/profile-picture.png';
    cancelConfirmMessage = '';
    cancelConfirmTitle = '';
    cancelConfirmAgreeButton = '';
    cancelConfirmCancelButton = '';
    userData = {};
    constructor(
        private formBuilder: FormBuilder,
        private itemService: ItemService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private location: Location,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService
    ) { }
    ngOnInit(): void {
        this.spinner.show('fullSpinner');
        this.getCountries();
        this.profileForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            address1: ['', [Validators.required]],
            address2: [''],
            postcode: ['', [Validators.required]],
            countryid: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            company: ['', Validators.required],
            taxnumber: [''],
            vat: [''],
            logo: [null]
        });

        this.changePasswdForm = this.formBuilder.group({
            oldpassword: ['', [Validators.required]],
            password: ['', [Validators.required]],
            confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
        },
            {
                validator: MustMatch('password', 'confirmpassword')
            });
        this.getUser();
    }
    get fProfile() { return this.profileForm.controls; }
    get fPass() { return this.changePasswdForm.controls; }

    getUser() {
        this.itemService.getAll('user').subscribe((response: any) => {
            if (response.hasOwnProperty('global')) {
                this.userData = response.global.user;

                this.populateForm();
                this.countryIdPlaceholder = '';
            }

        });
    }

    populateForm() {
        this.profileForm.patchValue({
            firstname: this.userData[`firstname`],
            lastname: this.userData[`lastname`],
            company: this.userData[`company`],
            email: this.userData[`email`],
            phone: this.userData[`phone`],
            address1: this.userData[`address1`],
            address2: this.userData[`address2`],
            countryid: this.userData[`countryid`],
            vat: this.userData[`vat`],
            taxnumber: this.userData[`taxnumber`],
            postcode: this.userData[`postcode`],
        });
        if (this.userData.hasOwnProperty('logo')) {
            this.imageSrc = environment.apiUrl + '/imgthumb/?hash=' + this.userData[`logo`][`hash`];
        } else {
            this.imageSrc = '../../../assets/images/profile-picture.png';
        }
        this.spinner.hide('fullSpinner');
    }
    getCountries() {
        this.itemService.getAll('country').subscribe((response: any) => {
            this.countries = response.data;
        });
    }

    countrySelected() {
        this.countryIdPlaceholder = '';
    }

    countryCleared() {
        this.countryIdPlaceholder = this.translate.instant('register.select_country');

    }
    fileUploaded(event) {
        const reader = new FileReader();
        const fileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (event.target.files && event.target.files[0]) {
            const [file] = event.target.files;

            if (file.size > 2097152) {

                this.fProfile.logo.setErrors({ filesize: true });
                this.toastr.warning(this.translate.instant('profile.file_size'));
                return;
            }
            if (!fileTypes.includes(file.type)) {

                this.fProfile.logo.setErrors({ filetype: true });
                this.toastr.warning(this.translate.instant('profile.file_type'));
                return;
            }
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.imageSrc = reader.result as string;
                this.profileForm.patchValue({
                    logo: file
                });
            };
            this.cd.markForCheck();
        }
    }

    cancel() {
        this.location.back();
    }
    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }
    profileSubmit() {
        this.profileSubmitted = true;
        if (this.profileForm.invalid) {

            return;
        }

        // body.toString()
        const formData: any = new FormData();
        formData.append('id', this.userData[`id`]);
        formData.append('firstname', this.fProfile.firstname.value);
        formData.append('lastname', this.fProfile.lastname.value);
        formData.append('company', this.fProfile.company.value);
        formData.append('email', this.fProfile.email.value);
        formData.append('phone', this.fProfile.phone.value);
        formData.append('countryid', this.fProfile.countryid.value);
        formData.append('address1', this.fProfile.address1.value);
        formData.append('address2', this.fProfile.address2.value);
        formData.append('postcode', this.fProfile.postcode.value);
        formData.append('vat', this.fProfile.vat.value);
        if (this.fProfile.logo.value != null) {
            formData.append('logo', this.fProfile.logo.value);
        }

        formData.append('taxnumber', this.fProfile.taxnumber.value);

        this.itemService.update('user', formData)
            .pipe(first())
            .subscribe(
                data => {
                    if (data[`success`] === true) {

                        this.toastr.success(this.translate.instant('profile.edit_success'));
                    } else {

                        this.toastr.error(this.translate.instant('global.global_error'));
                        this.loading = false;
                    }

                },
                error => {

                    this.toastr.error(this.translate.instant('global.global_error'));
                    this.loading = false;
                });
    }

    passSubmit() {
        this.passSubmitted = true;
        if (this.changePasswdForm.invalid) {
            return;
        }
        let body = new HttpParams()
            .set('new', `${this.fPass.password.value}`);
        // body.toString()
        // this.itemService.action('invoice',JSON.stringify(this.mailForm.value),'email')
        body = body.append('id', this.userData[`id`]);
        body = body.append('old', `${this.fPass.oldpassword.value}`);

        const load = JSON.stringify({
            new: this.fPass.password.value,
            old: this.fPass.oldpassword.value,
            id: this.userData[`id`]
        });
        this.itemService.action('user', load, 'changepass')
            .pipe(first())
            .subscribe(
                data => {
                    if (data[`result`][`success`] === true) {

                        this.toastr.success(this.translate.instant('profile.edit_success'));
                        this.passSubmitted = false;
                        this.changePasswdForm.reset();
                        this.logout();
                    } else {

                        this.toastr.error(this.translate.instant('profile.bad_old_pass'));
                        this.loading = false;
                    }

                },
                error => {

                    this.toastr.error(this.translate.instant('global.global_error'));
                    this.loading = false;
                });
    }
}
