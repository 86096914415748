import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ClientService {
    constructor(private http: HttpClient) { }

    getAll() {// get<client[]>
        return this.http.get(`${environment.apiUrl}/item/client`);
    }

    getById(id: number) { // get<client>
        return this.http.get(`${environment.apiUrl}/item/client?id=${id}`);
    }

    add(client: any) {
      return this.http.post(`${environment.apiUrl}/item/client`, client, { withCredentials: true });
    }

    delete(id: number) {
      return this.http.delete(`${environment.apiUrl}/client/${id}`);
    }

    sendInvitation(emailInvite) {
      return this.http.post(`${environment.apiUrl}/client/invite`, emailInvite);
    }
}
