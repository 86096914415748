import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, AuthenticationService } from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    lang: string;
    langs: Array<string> = [];
    username = '';
    rememberMe = true;
    environment = environment;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private translate: TranslateService,
        private toastr: ToastrService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
    }

    ngOnInit() {

        this.lang = this.translate.currentLang;
        this.langs = this.translate.getLangs();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
    get f(): any{
        return this.loginForm.controls;
      }

    switchLang(lang){
       // alert(lang);
        this.translate.use(lang);
        this.lang = lang;
    }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value, this.rememberMe )
     .subscribe({
         next: data => {
             console.log(data);
             if (data[`token`]) {
                this.toastr.success(this.translate.instant('login.success_login'));
                this.router.navigate([this.returnUrl]);
             }
             if (data[`error`] === 'LOGIN_USER_INACTIVE'){
                this.router.navigate(['/userinactive'], {queryParams: {mail: this.f.username.value}});
             }
             if (data[`error`] === 'LOGIN_LOGIN_FAILED'){
                this.toastr.error(this.translate.instant('login.error_login'));
                this.loading = false;
             }
         },
         error: () => {
             this.toastr.error(this.translate.instant('global.global_error'));
             this.loading = false;
         }
     });
    }
}
