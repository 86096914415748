import { Component, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, UserService, AuthenticationService, ItemService } from '@app/_services';
import { Subject } from 'rxjs/internal/Subject';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { ToastrService } from 'ngx-toastr';
import { CookieService} from 'ngx-cookie-service';
import { ElementSchemaRegistry } from '@angular/compiler';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons, NgbModalOptions, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
@Component({ templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']})

export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    registerSuccess = false;
    loading = false;
    submitted = false;
    countries = [];
    countryInput = new Subject<string>();
    placeholder = '';
    lang = this.translate.currentLang;
    referal = null;
    langs: Array<string> = [];
    modalReference: any;
    modalOptions: NgbModalOptions;
    closeResult: string;
    environment = environment;
    mailNotUnique = false;
    captchaToken = '';
    constructor(
        private recaptchaV3Service: ReCaptchaV3Service,
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private itemService: ItemService,
        public translate: TranslateService,
        public toastr: ToastrService,
        public cookieService: CookieService,
        private modalService: NgbModal,
    ) {

        this.modalOptions = {
           backdrop: 'static',
           backdropClass: 'customBackdrop',
           size: 'xl',
           scrollable: true
            };
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
        this.countryInput.subscribe((countryTerm) => {
         // alert(countryTerm);
          this.updateCountries(countryTerm);
      });
    }

    ngOnInit() {
      if (this.cookieService.check('referal')){
        this.referal = this.cookieService.get('referal');
      }
    //  console.log('register cookie' + this.referal);
      this.lang = this.translate.currentLang;
     // console.log('current lang' + this.lang);

      this.langs = this.translate.getLangs();
      this.getCountries();
      this.initForm();
      this.registerForm.get('email').valueChanges.subscribe(x => {
           if(this.submitted) {this.mailNotUnique = false; };
     });
    }
    open(content){
      this.modalReference = this.modalService.open(content, this.modalOptions);
      this.modalReference.result.then((result) => {
        this.closeResult = ` Closed with ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC){
        return 'by pressing ESC';
      }else if (reason === ModalDismissReasons.BACKDROP_CLICK){
        return 'by clicking on a backdrop';
      }else{
        return `with: ${reason}`;
      }
    }

initForm(){
  this.registerForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    countryid: [null, [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmpassword: ['', [Validators.required]],
    acceptTerms: [false, Validators.requiredTrue]
},
{
  validator: MustMatch('password', 'confirmpassword')
}
);
}
    // convenience getter for easy access to form fields
    get f(): any{
      return this.registerForm.controls;
    }
    getCountries(){
      this.itemService.getAll('country').subscribe((response: any) => {
        this.countries = response.data;
      });
    }

    updateCountries(search: string){
      this.itemService.itemSearch('country', search).subscribe((response: any) => {

        this.countries = response.data;
      });
    }

switchLang(lang){
      // alert(lang);
       this.translate.use(lang);
       this.lang = lang;
      // localStorage.setItem('userLang',lang);
   }
changeMail(){
       this.mailNotUnique = false;
   }

 getCaptcha(){
    return this.recaptchaV3Service.execute('importantAction');
}
 onSubmit = async () => {
        this.submitted = true;
        this.mailNotUnique = false;
        // reset alerts on submit
        this.alertService.clear();
        const token$ = this.getCaptcha();
        const token = await lastValueFrom(token$);
        // stop here if form is invalid
        if (this.registerForm.invalid || !token) {
            return;
        }else{
            const formData: any = new FormData();
            const formValues = this.registerForm.value;
            delete formValues.confirmpassword;
            delete formValues.acceptTerms;

            for (const [key, value] of Object.entries(formValues)) {
            formData.append(key, value);
          }
            if (this.referal){
            formData.append('referal', this.referal);
          }
            formData.append('language', this.lang);
            formData.append('key', token);

            this.loading = true;
            this.itemService.add('user', formData)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data[`success`]){
                        this.registerSuccess = true;
                        this.toastr.success(this.translate.instant('register.successfully_registered'));
                        // this.router.navigate(['/login']);
                      }else{
                        if (data[`saveErrors`]){

                          if (data[`saveErrors`].find(element =>
                            element.field === 'email' && element.validator === 'unique'
                          )){
                              // this.toastr.warning(this.translate.instant('register.mail_unique'));
                              this.mailNotUnique = true;
                              return ;
                          }

                        }
                        this.toastr.warning(this.translate.instant('global.global_error'));

                      }
                  },
                  error => {
                     this.toastr.warning(this.translate.instant('global.global_error'));

                  });
            this.loading = false;
      }
        }

}
