import {Pipe, PipeTransform} from '@angular/core';

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({ name: 'contactsFilter' })
export class ContactsFilterPipe implements PipeTransform{

    transform(value: string[], q: string) {
        if (!q || q === '') {

            return value;

        }
        const filteredCompanies = value.filter(item => -1 < item[`company`].toLowerCase().indexOf(q.toLowerCase()));
        const filteredNames = value.filter(item => -1 < item[`fullname`].toLowerCase().indexOf(q.toLowerCase()));
        const filteredEmails = value.filter(item => -1 < item[`email`].toLowerCase().indexOf(q.toLowerCase()));
        const joinedTable = filteredNames.concat(filteredCompanies, filteredEmails);
        const uniqueRowsTable = joinedTable.filter((item, pos) => joinedTable.indexOf(item) === pos);
        return uniqueRowsTable;
    }
}
