import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dns-settings',
  templateUrl: './dns-settings.component.html',
  styleUrls: ['./dns-settings.component.scss']
})
export class DnsSettingsComponent implements OnInit {
  lang: string = '';
  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    
  }

}
