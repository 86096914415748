import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

import { Client, Role, User } from '@app/_models';

const defaultUsers: User[] = [
    { id: 1, username: 'admin', password: 'admin', firstName: 'Admin', lastName: 'User', role: Role.Admin },
    { id: 2, username: 'user', password: 'user', firstName: 'Normal', lastName: 'User', role: Role.User }
];

const defaultClients: Client[] = [
    { id: 1, name: 'Client 1 Name', email: 'client1@client.ro', user: 'user' },
    { id: 2, name: 'Client 2 Name', email: 'client2@client.ro', user: 'user' },
];

// array in local storage for registered users
let users = (JSON.parse(localStorage.getItem('users')) || []).concat(defaultUsers);
const clients = (JSON.parse(localStorage.getItem('clients')) || []).concat(defaultClients);

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/login') && method === 'POST':
                    return authenticate();
                case url.endsWith('/users/register') && method === 'POST':
                    return register();
                case url.endsWith('/users/invite') && method === 'POST':
                    return inviteUsers();
                case url.endsWith('/users') && method === 'GET':
                    return getUsers();
                case url.match(/\/users\/\d+$/) && method === 'GET':
                    return getUserById();
                case url.match(/\/users\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                case url.endsWith('/clients/add') && method === 'POST':
                    return addClient();
                case url.endsWith('/clients') && method === 'GET':
                    return getClients();
                case url.match(/\/clients\/\d+$/) && method === 'GET':
                    return getClientById();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }

        }

        // route functions

        function authenticate() {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) {
                return error('Username or password is incorrect');
            }
            return ok({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                token: `fake-jwt-token.${user.id}`
            });
        }

        function register() {
            const user = body;

            if (users.find(x => x.username === user.username)) {
                return error('Username "' + user.username + '" is already taken');
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
            localStorage.setItem('users', JSON.stringify(users));

            return ok();
        }

        function addClient() {
            const client = body;

            if (clients.find(x => x.name === client.name)) {
                return error('Client Name "' + client.name + '" is already taken');
            }

            client.id = clients.length ? Math.max(...clients.map(x => x.id)) + 1 : 1;
            clients.push(client);
            localStorage.setItem('clients', JSON.stringify(clients));

            return ok();
        }

        function inviteUsers() {
            return ok();
        }

        function getUsers() {
            if (!isAdmin()) {
                return unauthorized();
            }
            return ok(users);
        }

        function getClients() {
            return ok(clients);
        }

        function getClientById() {
            if (!isLoggedIn()) {
                return unauthorized();
            }

            // only admins can access other user records
            // if (!isAdmin() && currentUser().id !== idFromUrl()) { return unauthorized(); }

            const client = clients.find(x => x.id === idFromUrl());
            return ok(client);
        }

        function getUserById() {
            if (!isLoggedIn()) {
                return unauthorized();
            }

            // only admins can access other user records
            if (!isAdmin() && currentUser().id !== idFromUrl()) {
                return unauthorized();
            }

            const user = users.find(x => x.id === idFromUrl());
            return ok(user);
        }

        function deleteUser() {
            if (!isLoggedIn()) {
                return unauthorized();
            }

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem('users', JSON.stringify(users));
            return ok();
        }

        // helper functions

        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'unauthorized' } });
        }

        function error(message) {
            return throwError({ status: 400, error: { message } });
        }

        function isLoggedIn() {
            const authHeader = headers.get('Authorization') || '';
            return authHeader.startsWith('Bearer fake-jwt-token');
        }

        function isAdmin() {
            return isLoggedIn() && currentUser().role === Role.Admin;
        }

        function currentUser() {
            if (!isLoggedIn()) {
                return;
            }
            const id = Number(headers.get('Authorization').split('.')[1]);
            return users.find(x => x.id === id);
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return Number(urlParts[urlParts.length - 1]);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
