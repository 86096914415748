import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  lang: string;
  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    console.log(this.lang);
  }

}
