import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ItemService {
    constructor(private http: HttpClient) { }

    getAll(itemtype: string) {
        return this.http.get(`${environment.apiUrl}/item/` + itemtype);
    }

    itemSearch(itemtype: string, searchString: string){
      return this.http.get(`${environment.apiUrl}/item/${itemtype}?q=${searchString}`);
  }

    getConfig(itemtype: string) {
      return this.http.request('CONFIG', `${environment.apiUrl}/item/` + itemtype);
    }

    getById(itemtype: string, id: number) { // get<Contact>
        return this.http.get(`${environment.apiUrl}/item/` + itemtype + `?id=${id}`);
    }

    add(itemtype: string, item: any) {
      return this.http.post(`${environment.apiUrl}/item/` + itemtype, item, { withCredentials: true });
    }

    action(itemtype: string, item: any, action: string){
      return this.http.post(`${environment.apiUrl}/item/` + itemtype + `/?action=${action}`, item, { withCredentials: true });
    }

    update(itemtype: string, item: any) {

      return this.http.patch(`${environment.apiUrl}/item/` + itemtype, item, {withCredentials: true});
    }

    delete(itemtype: string, id: number) {
      return this.http.delete(`${environment.apiUrl}/item/${itemtype}?id=${id}`);
    }

    deleteFile(itemtype: string, itemId: string, fileId: string) {
      return this.http.delete(`${environment.apiUrl}/item/${itemtype}/?id=${itemId}&fileId=${fileId}`);
    }

    fileDownload(filetype: string, hash: string) {
      return this.http.get(`${environment.apiUrl}/${filetype}/?hash=${hash}`, {responseType: 'blob'});
    }

    transformFieldsConfig(fields) {
      const configTemplate = [];
      Object.entries(fields).forEach(
          ([key, value]) => {
            configTemplate.push({
              type: value[`type`],
              label: key,
            });
          }
        );
      return configTemplate;
    }
}
