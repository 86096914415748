import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    request = request.clone({
        setHeaders: {
            'Accept-Language': '*'
        }
    });
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          'X-Authorization': `${currentUser.token}`,
          'Accept-Language': '*'
        },
        params: (request.params ? request.params : new HttpParams()).set('rand', Math.random())
      });
      // format response to have only data object on response
      return next.handle(request).pipe(
        filter(event => event instanceof HttpResponse),
        map((event: HttpResponse<any>) => event.clone({body: event.body}))
      );
    }else{
        request = request.clone({
            setHeaders: {
                'Accept-Language': '*'
            },
            params: (request.params ? request.params : new HttpParams()).set('once_req_key', Math.random())
        });
        return next.handle(request).pipe(
            filter(event => event instanceof HttpResponse),
            map((event: HttpResponse<any>) => event.clone({body: event.body}))
          );
    }

    return next.handle(request);
  }
}
