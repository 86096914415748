import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent, InviteComponent } from './admin';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { ErrorInterceptor, JwtInterceptor } from '@app/_helpers';
import { SharedModule } from '@app/_shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormBuilderComponent } from './_shared/form-builder/form-builder.component';
import { FormFieldsComponent } from './_shared/form-fields/form-fields.component';
import { FormTestComponent } from './form-test/form-test.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContactsComponent } from './modules/contacts/contacts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProfileComponent } from './user/profile/profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerService } from './_services/spinner.service';
import { NgChartsModule } from 'ng2-charts';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import { PasswordForgotComponent } from './user/password-forgot/password-forgot.component';
import { RegisterConfirmComponent } from './user/register-confirm/register-confirm.component';
import { DnsSettingsComponent } from './modules/webpage/dns-settings/dns-settings.component';
import { TermsComponent } from './user/terms/terms/terms.component';
import { TermsEnComponent } from './user/terms/terms-en/terms-en.component';
import { TermsDeComponent } from './user/terms/terms-de/terms-de.component';
import { TermsRoComponent } from './user/terms/terms-ro/terms-ro.component';
import { UserInactiveComponent } from './user/user-inactive/user-inactive.component';
import { environment } from '@environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        RecaptchaV3Module,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FontAwesomeModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        NgSelectModule,
        NgbModule,
        NgxSpinnerModule,
        NgChartsModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger', // set defaults here
        }),
        NgxPaginationModule
    ],
    declarations: [
        AppComponent,
        AdminComponent,
        InviteComponent,
        LoginComponent,
        RegisterComponent,
        FormBuilderComponent,
        FormFieldsComponent,
        FormTestComponent,
        ContactsComponent,
        ProfileComponent,
        PasswordResetComponent,
        PasswordForgotComponent,
        RegisterConfirmComponent,
        DnsSettingsComponent,
        TermsComponent,
        TermsRoComponent,
        TermsDeComponent,
        TermsEnComponent,
        UserInactiveComponent
    ],
    exports: [TranslateModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        SpinnerService,
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey}
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor() {
    }
}
