import { Component, Renderer2, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Role, User } from '@app/_models';
import { AuthenticationService, ItemService } from '@app/_services';
import { ThemeService } from './_services/theme.service';
import { SettingsService } from './_services/settings.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    title = environment.apiUrl + '- the Startup Tool';
    currentUser: User;
    currentUrl = '';
    browserLang = null;
    ref = null;
    referalCookie = null;
    userLang = '';
    userSettings = { lang: null, theme: 'boostrap' };
    theme = 'bootstrap';
    loading = true;
    userDetails = {};
    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private themeService: ThemeService,
        private settingsService: SettingsService,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService,
        private spinner: NgxSpinnerService,
        private itemService: ItemService,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document

    ) {
        this.currentUrl = window.location.href;
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.activatedRoute.queryParams.subscribe(params => {
            this.ref = params[`ref`];
            this.checkReferal();
        });
    }
    ngOnInit(): void {
        const script = this.renderer2.createElement('script');
        const script2 = this.renderer2.createComment('script2');
        script.type = 'application/ld+json';
        script2.type = `application/ld+json`;
        script.text = `async src="https://www.googletagmanager.com/gtag/js?id=AW-1017005541"`;
        script2.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-1017005541');`;
        this.renderer2.appendChild(this.document.body, script);
        this.renderer2.appendChild(this.document.body, script2);
        this.spinner.show();
        this.getSettings();
    }

    getUser(){
        this.itemService.getAll('user')
        .subscribe({
            next: response => {
                this.userDetails = response[`global`][`user`];
            },
            error: () => {
                this.toastr.error(this.translate.instant('global.error'));
            }
        });
    }
    checkReferal() {
        if (this.ref) {
            if (!this.cookieService.check('referal')) {
                this.cookieService.set('referal', this.ref, { expires: 31, path: '/', sameSite: 'Lax', domain: 'peezzer.com' });
            }
        }

    }

    setLanguage() {
        this.browserLang = navigator.language;
        this.translate.addLangs(environment.languages);
/*         for (const language of this.translate.getLangs()) {
            console.log(language);
            if (this.browserLang.includes(language)){
                this.userLang = language;
                console.log(this.browserLang);
            }
        } */
        if (this.userSettings && this.userSettings[`lang`]) {
            this.userLang = this.userSettings[`lang`];
        }
        if (this.userLang === ''){
            this.userLang = this.translate.getLangs()[0];
        }
        console.log(this.userLang);
        this.translate.use(this.userLang);
        this.setTheme();
    }

    getLocation() {
        if (!navigator.geolocation) {
            console.log('location not supported');
        }
        navigator.geolocation.getCurrentPosition((position) => {
            console.log(`lat: ${position.coords.latitude}, lon: ${position.coords.longitude}`);
        }, (err) => {
            console.log(err);
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    }
    setTheme() {
        if (this.userSettings && this.userSettings.hasOwnProperty('theme')) {
            this.themeService.setTheme(this.userSettings[`theme`]);

        } else {
            this.themeService.setTheme('bootstrap');

        }

        this.themeService.themeChanges().subscribe(theme => {
            if (theme.oldValue) {
                this.renderer.removeClass(document.body, theme.oldValue);
            }
            this.renderer.addClass(document.body, theme.newValue);
            console.log(theme.newValue);
        });

        this.loading = false;
        this.spinner.hide();
    }
    getSettings = async () => {
        if (this.currentUser) {
            this.userSettings = await this.settingsService.getModuleSettings('userSettings');
            this.setLanguage();

        } else {
            this.setLanguage();
        }
    }
    updateSettings = async () => {
        const userSettingsResponse = await this.settingsService.saveModuleSettings('userSettings', this.userSettings);
        console.log(userSettingsResponse);
        //
    }

    get isAdmin() {
        return this.currentUser && this.currentUser.role === Role.Admin;
    }

    get isLogged() {
        return this.currentUser;
    }

    logout() {
        //this.userSettings = {};
        this.settingsService.deleteSettings();
        alert('logout');
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
