import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ItemService } from '@app/_services/item.service';
import { first } from 'rxjs/operators';
import { Subscription, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss']
})
export class FormFieldsComponent implements OnInit {
  @Input() buildedForm: FormGroup;
  @Input() formField: any;
  items: any =[];
  searchString: string ='';
  placeHolder: string ='Select One';
  constructor(
    private itemService: ItemService
  ) { }

  ngOnInit(): void {
    this.fieldsPrepare();
  }
  get f(): any{
    return this.buildedForm.controls;
  }
  fieldsPrepare(){
    
    
    if(this.formField.itemtype){
      
    }
  }

  updateItems(){
    if(this.f.field.value){
      let searchString = this['buildedForm.'+this.formField.name+'.value']
      this.itemService.itemSearch(this.formField.itemtype,searchString)
      .pipe(first())
      .subscribe(
        (data: any) => {
          
          this.items = data.data
        }
      )
    }else{
      this.placeHolder = 'Select One';
    }

  }


}
