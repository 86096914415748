<form [formGroup]="inviteForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <input type="email" formControlName="emailInvite" class="form-control"
           [ngClass]="{ 'is-invalid': submitted && f.emailInvite.errors }"/>
    <div *ngIf="submitted && f.emailInvite.errors" class="invalid-feedback">
      <div *ngIf="f.emailInvite.errors.required">Valid Email address is required</div>
      <div *ngIf="f.emailInvite.errors.email">Email must be a valid email address</div>
    </div>
  </div>
  <div class="form-group">
    <button [disabled]="loading" type="submit" class="btn btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Invite
    </button>
  </div>
</form>
