import { Injectable } from '@angular/core';
import { ItemService} from './item.service';
import {first} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  settings: Array<any> = [];

  constructor(private itemService: ItemService){}

  async getModuleSettings(moduleName: string) {
    console.log('get settings ' + moduleName);

    if (moduleName in this.settings) {
      return this.settings[moduleName];
    } else {
      // get the data and convert it to data from tht json string
      let items = [];
      const response = await this.itemService.getAll('setting')
      .toPromise()
      .catch(error => {

        return [];
      });
      if (response[`data`]){
          console.log(response);
          items = response[`data`];

          if (items.length > 0 ){
          for (const item of items) {
            // item = JSON.parse(item);
            const module = item.module;
            const value = item.value;
            this.settings[module] = JSON.parse(value);
            this.settings[module].id = item.id;
          }
          if (moduleName in this.settings) {
              console.log(this.settings[moduleName]);
              return this.settings[moduleName];

          } else {
            return [];
          }
        } else {

          return [];
        }
      }
    }
  }

  async saveModuleSettings(moduleName: string, data: any) {
      console.log('moduleName' + moduleName);
      console.log('value' + data);
      const val = {
      module: moduleName,
      value: JSON.stringify(data)
    };

      if (moduleName in this.settings) {
      // we update
      const objid = this.settings[moduleName].id;
      val[`id`] = objid;
      this.settings[moduleName] = data;
      let response = await this.itemService.update('setting', val)
      .toPromise()
      .catch(error =>
        {
          return response = {success: false};
        });

      if (response[`success`] === true){
        this.settings[moduleName] = JSON.parse(response[`data`].value);
        this.settings[moduleName][`id`] = response[`data`].id;
        return(response);
      }else{
        return (response);
      }
    } else {
      const response  = await this.itemService.add('setting', val)
      .toPromise()
      .catch((error) =>
        {
         //
          return {success: false};
        });
     //
      if (response[`success`] === true){
        this.settings[moduleName] = JSON.parse(response[`data`].value);
        this.settings[moduleName][`id`] = response[`data`].id;
        return(response);
      }else{
        return (response);
      }
    }

  }
  deleteSettings(){
    this.settings = [];
  }
}
