import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ContactService {
    constructor(private http: HttpClient) { }

    getAll() {// get<Contact[]>
        return this.http.get(`${environment.apiUrl}/item/contact`);
    }

    getConfig() {
      return this.http.request('CONFIG', `${environment.apiUrl}/item/contact`);
    }

    getById(id: number) { // get<Contact>
        return this.http.get(`${environment.apiUrl}/item/contact?id=${id}`);
    }

    add(contact: any) {
      return this.http.post(`${environment.apiUrl}/item/contact`, contact, { withCredentials: true });
    }

    update(contact: any) {
      return this.http.patch(`${environment.apiUrl}/item/contact`, contact, { withCredentials: true });
    }

    delete(id: number) {
      return this.http.delete(`${environment.apiUrl}/item/contact?id=${id}`);
    }

    sendInvitation(emailInvite) {
      return this.http.post(`${environment.apiUrl}/item/contact/invite`, emailInvite);
    }

    transformFieldsConfig(fields) {
      const configTemplate = [];
      Object.entries(fields).forEach(
          ([key, value]) => {
            configTemplate.push({
              type: value['type'],
              label: key,
            });
          }
        );
      return configTemplate;
    }
}
