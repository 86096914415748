import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { UserService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  loading = false;
  submitted = false;
  passResetForm: FormGroup;
  hash = '';
  email = '';
  lang = '';
  environment = environment;

  constructor(
  private formBuilder: FormBuilder,
  private userService: UserService,
  private toastr: ToastrService,
  private router: Router,
  private translate: TranslateService,
  private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.hash = params[`hash`];
      this.email = params[`email`];

    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.passResetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('password', 'confirmpassword')
      });
  }

  switchLang(lang) {
    // alert(lang);
    this.translate.use(lang);
    this.lang = lang;
  }
  get f() { return this.passResetForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.passResetForm.invalid) {
      return;
    }
    let body = new HttpParams()
      .set('password', `${this.f.password.value}`);
    // body.toString()
    body = body.append('email', this.email);
    body = body.append('hash', this.hash);

    this.userService.passReset(body)
      .subscribe({
        next: data => {
          if (data[`success`] === true) {

            this.toastr.success(this.translate.instant('profile.pass_change_success'));
            this.router.navigate(['/login']);
          } else {

            this.toastr.error(this.translate.instant('global.global_error'));
            this.loading = false;
          }
        },
        error: error => {
          this.toastr.error(this.translate.instant('global.global_error'));
          this.loading = false;
        }
      });
  }

}
