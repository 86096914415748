<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
  <div class="container-xxl">
      <div class="row">
          <div class="col-lg-12">
              <div class="bg-white shadow rounded">
                  <div class="row">
                      <div class="col-md-7 pe-0">
                          <div class="form-left h-100 py-5 px-5">

                              <div class="row text-end" style="padding:10px;" *ngIf="environment.websiteUrl === 'peezzer.com'">
                                <div class="col-md-12 " >
                                        <img src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                        <img src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                        <img src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                                </div>  
     
                            </div>
                            <div class="row mobile-logo">
                              <div class="col-12 text-center" style="margin-bottom:30px;">
                                  <img src="../../assets/images/logo-vertical.png" width="30%">
                              </div>
                          </div>                     
                              <div *ngIf="!registerSuccess">
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                                    <div class="row input-row">
                                      <div class="col-md-6 mx-auto">
                                          <div  class="mb-1">
                                              <label  class="form-label">{{ 'register.email' | translate }}</label>
                                              <input type="email" class="form-control" [class.is-invalid]="(f.email.invalid && submitted) || mailNotUnique" formControlName="email" (change)="changeMail()">
                                                <div *ngIf="f.email.invalid && submitted" class="invalid-feedback">
                                                  <div  *ngIf="f.email.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.email' | translate } }}</div>
                                                  <div  *ngIf="f.email.errors?.email" >{{ 'client_form.invalid_email' | translate }}</div>
                                                  </div>
                                                  <div *ngIf="mailNotUnique" class="invalid-feedback">
                                                      <div>
                                                        {{'register.mail_unique' | translate}}
                                                      </div>
                                                  </div>
                                            </div>
                                      </div>

                                    </div>
                                    <div class="row input-row">
                                        <div class="col-md-6 mx-auto">
                                            <div class="mb-1">
                                              <label  class="form-label">{{ 'register.country' | translate }}</label>
                                              <ng-select 
                                              [ngClass]="{'is-invalid': submitted && f.countryid.invalid}"
                                              [items]="countries"
                                              placeholder="{{'register.select_country'|translate}}"
                                              [minTermLength] = 2
                                              [searchable]="true"
                                              [clearable]="true"
                                              bindLabel="name"
                                              bindValue="id"
                                              loadingText="Loading..."
                                              formControlName="countryid">
                                             </ng-select>
                                             <div *ngIf="f.countryid.invalid && submitted">
            
                                              <div  *ngIf="f.countryid.errors?.required" class="invalid-feedback" >{{ 'register.validator_required' | translate: {value: 'register.country' | translate } }}</div>
                                           </div>
                                            </div>
                                        </div>
                                    </div>
                                            <div class="row input-row">
                                              <div class="col-12 col-md-6 mx-auto">
                                                <div class="mb-1">
                                                  <label  class="form-label">{{ 'register.password' | translate }}</label>
                                                  <input type="password" class="form-control" [class.is-invalid]="f.password.invalid && submitted" formControlName="password" >
                                                    <div *ngIf="f.password.invalid && submitted" class="invalid-feedback">
                                                      <div  *ngIf="f.password.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.password' | translate } }}</div>
                                                      <div  *ngIf="f.password.errors?.minlength" >{{ 'register.min_length'| translate }}</div>
                                                      </div>
                                                </div>
                                              </div>

                                              </div>
                                              <div class="row input-row">
                                                <div class="col-md-6 mx-auto">
                                                    <div class="mb-1">
                                                      <label  class="form-label">{{ 'register.confirmpassword' | translate }}</label>
                                                      <input type="password" class="form-control" [class.is-invalid]="f.confirmpassword.invalid && submitted" formControlName="confirmpassword" >
                                                        <div *ngIf="f.confirmpassword.invalid && submitted" class="invalid-feedback">
                                                          <div  *ngIf="f.confirmpassword.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.confirmpassword' | translate } }}</div>
                                                          <div *ngIf="f.confirmpassword.errors.mustMatch">{{'register.password_must_match'|translate}}</div>
                                                          </div>
                                                    </div>
                                               </div>
                                              </div>
                                              <div class="row input-row">
                                                <div class="col-12 col-md-6 mx-auto">
                                                  <div class="mb-1">
                                                    <div class="form-check form-check-inline">
                                                      <input id="acceptTerms" class="form-check-input mr-1" name="acceptTerms" type="checkbox" formControlName="acceptTerms">
                                                      <label for="acceptTerms" class="form-check-label">   {{'global.please_accept' | translate}}  <span (click)="open(termsModal,$event);false" class="terms-link">{{'global.terms_title' | translate}}</span> </label>
                                                    </div>
                                                    <div *ngIf="f.acceptTerms.invalid && submitted">
                                                      <div *ngIf="f.acceptTerms.errors?.required" class="invalid-feedback"> {{'global.terms_error' | translate}}</div>
                                                    </div>
                                                  </div>

                                                </div>        
                                              </div>
                                      <div class="row">
                                      <div class="col-12">
                                          <button [disabled]="loading" type="submit" class="btn btn-primary px-4 float-end mt-4">{{'register.submit' | translate}}<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
                                      </div>
                                    </div>
                                    <div class="row mt-5">
                                      <div class="col-sm-12">
                                          <div class="form-check">
                                              <div >{{'register.already_have_account' | translate}}  <a [routerLink]="['/login']"> {{'login.login' | translate}}</a> </div>
                                          </div>
                                      </div>
                                      </div>
                              </form>
                            </div>
                            <div *ngIf="registerSuccess">
                              <div class="row mt-5">
                                <div class="col-12 text-center">
                                  <i class="fa fa-check-circle fa-lg success-icon" aria-hidden="true"></i>
                                </div>
                            </div>
                                
                                <div class="row p-3 text-center">
                                <p>{{'register.regist_success_msg' | translate}}</p>
                                </div>
                                <div class="row p-3 text-center">
                                  <p>{{'register.confirm_mail_msg' | translate}}</p>
                                  </div>
                                
                                <div class="row">
                                 <div class="col-lg-4 col-sm-12 mx-auto text-center mb-5">
                                    <a [routerLink]="['/']" class="btn btn-primary "> {{'login.login' | translate}}</a>
                                 </div>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div class="col-md-5 ps-0 d-none d-md-block">
                          <div class="form-right h-100 bg-primary text-white text-center pt-5">
                            <img src="../../assets/images/logo-vertical.png" width="50%">
                              <div class="register-list px-5 w-75 py-5 mx-auto">
                                <ul class="list-unstyled text-start mt-5">
                                    <li class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.invoice_module' | translate}}</li>
                                    <hr style="margin:0.5em 0">
                                    <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.manage_contacts' | translate}} </li>
                                    <hr style="margin:0.5em 0">
                                    <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.webpage_creator' | translate}}</li>
                                    <hr style="margin:0.5em 0">
                                    <li  class="ml-2"><i class="fa fa-check mr-1 text-info" aria-hidden="true"></i> {{'payment.analytics' | translate}}</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                     
                  </div>
                </div>
          </div>
      </div>
  </div>
</div>

<ng-template #termsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'global.terms_title' | translate}}</h4>
    <button type="button" class="close btn btn-sm btn-light" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div *ngIf="lang == 'en'">
        <app-terms-en></app-terms-en>
      </div>
      <div *ngIf="lang == 'de'">
        <app-terms-de></app-terms-de>
      </div>
      <div *ngIf="lang == 'ro'">
        <app-terms-ro></app-terms-ro>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
