import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  mailSent = false;
  environment = environment;
  lang: string;
  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;

    this.forgotForm = this.formBuilder.group({
      email: ['', Validators.required]
  });
  }

  get f(): any{
    return this.forgotForm.controls;
  }

  switchLang(lang){
    // alert(lang);
     this.translate.use(lang);
     this.lang = lang;
 }
back(){
    this.router.navigate(['/login']);
}
 onSubmit() {
  this.submitted = true;
  if (this.forgotForm.invalid) {
      return;
  }

  this.loading = true;
  this.userService.passResetRequest(this.f.email.value)
      .pipe(first())
      .subscribe(
          data => {
              if (data[`success`]) {
                  this.mailSent = true;

              }else{
                this.toastr.error(this.translate.instant('login.error_login'));
                this.loading = false;
                return;
              }

          },
          error => {
              this.toastr.error(error);
              this.loading = false;
          });
}

}
