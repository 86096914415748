export const environment = {
  production: false,
  url: 'https://api.staging.peezzer.com/websites',
  apiUrl: 'https://api.staging.peezzer.com/api',
  siteName: 'Peezzer',
  websiteUrl: 'peezzer.com',
  languages: ['en', 'de', 'ro'],
  recaptcha: {
    siteKey: '6LcWy1MfAAAAAJakcdSyZaOR-GF3gPhAe7AxlpqI',
  }
};
