import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService } from '@app/_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  inviteForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
              private alertService: AlertService,
              private userService: UserService) { }

  // convenience getter for easy access to form fields
  get f() { return this.inviteForm.controls; }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      emailInvite: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.inviteForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.sendInvitation(this.inviteForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success('Invite sent successful', true);
          this.loading = false;
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

}
