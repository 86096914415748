import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { ProfileComponent } from './user/profile/profile.component';
import { FormTestComponent } from './form-test/form-test.component';
import { FormBuilderComponent } from './_shared/form-builder/form-builder.component';
import { PasswordForgotComponent } from './user/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import {RegisterConfirmComponent} from './user/register-confirm/register-confirm.component';
import { SettingsComponent } from './modules/invoices';
import { DnsSettingsComponent } from './modules/webpage/dns-settings/dns-settings.component';
import { TermsComponent } from '../app/user/terms/terms/terms.component';
import {UserInactiveComponent} from '../app/user/user-inactive/user-inactive.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@app-modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notes',
    loadChildren: () => import('@app-modules/notes/notes.module').then(m => m.NotesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clients',
    loadChildren: () => import('@app-modules/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices',
    loadChildren: () => import('@app-modules/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('@app-modules/products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'webpage',
    loadChildren: () => import('@app-modules/webpage/webpage.module').then(m => m.WebpageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('@app-modules/payment/payment.module').then(m => m.PaymentModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'userinactive',
    component: UserInactiveComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'form-test',
    component: FormTestComponent
  },
  {
    path: 'form-builder',
    component: FormBuilderComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {roles: [Role.Admin]}
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'passforgot',
    component: PasswordForgotComponent
  },
  {
    path: 'passreset',
    component: PasswordResetComponent
  },
  {
    path:'register-confirm',
    component: RegisterConfirmComponent

  },
{
  path: 'domain-settings',
  component: DnsSettingsComponent
},
  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
