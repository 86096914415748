<footer class="footer">
  <div class="footer-top">
    <div class="container-xxl">
      <div class="row">
        <div class="col-sm-6">
          <address>
            <p>143 castle road 517</p>
            <p class="mb-4">district, kiyev port south Canada</p>
            <div class="d-flex align-items-center">
              <p class="mr-4 mb-0">+3 123 456 789</p>
              <a href="mailto:info@yourmail.com" class="footer-link">info@yourmail.com</a>
            </div>
            <div class="d-flex align-items-center">
              <p class="mr-4 mb-0">+1 222 345 342</p>
              <a href="mailto:Marshmallow@yourmail.com" class="footer-link">Marshmallow@yourmail.com</a>
            </div>
          </address>
          <div class="social-icons">
            <h6 class="footer-title font-weight-bold">
              Social Share
            </h6>
            <div class="d-flex">
              <a href="#"><i class="mdi mdi-github-circle"></i></a>
              <a href="#"><i class="mdi mdi-facebook-box"></i></a>
              <a href="#"><i class="mdi mdi-twitter"></i></a>
              <a href="#"><i class="mdi mdi-dribbble"></i></a>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-4">
              <h6 class="footer-title">Social Share</h6>
              <ul class="list-footer">
                <li><a href="#" class="footer-link">Home</a></li>
                <li><a href="#" class="footer-link">About</a></li>
                <li><a href="#" class="footer-link">Services</a></li>
                <li><a href="#" class="footer-link">Portfolio</a></li>
                <li><a href="#" class="footer-link">Contact</a></li>
              </ul>
            </div>
            <div class="col-sm-4">
              <h6 class="footer-title">Product</h6>
              <ul class="list-footer">
                <li><a href="#" class="footer-link">Digital Marketing</a></li>
                <li><a href="#" class="footer-link">Web Development</a></li>
                <li><a href="#" class="footer-link">App Development</a></li>
                <li><a href="#" class="footer-link">Design</a></li>
              </ul>
            </div>
            <div class="col-sm-4">
              <h6 class="footer-title">Company</h6>
              <ul class="list-footer">
                <li><a href="#" class="footer-link">Partners</a></li>
                <li><a href="#" class="footer-link">Investors</a></li>
                <li><a href="#" class="footer-link">Partners</a></li>
                <li><a href="#" class="footer-link">FAQ</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container-xxl">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img src="assets/images/logo-peezzer.svg" alt="logo" class="mr-3">
          <p class="mb-0 text-small pt-1">© 2019-2020 <a href="https://www.bootstrapdash.com" class="text-white" target="_blank">BootstrapDash</a>. All rights reserved.</p>
        </div>
        <div>
          <div class="d-flex">
            <a href="#" class="text-small text-white mx-2 footer-link">Privacy Policy </a>
            <a href="#" class="text-small text-white mx-2 footer-link">Customer Support </a>
            <a href="#" class="text-small text-white mx-2 footer-link">Careers </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
