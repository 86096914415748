<app-layout>
  <ngx-spinner 
  bdColor = "rgba(0, 0, 0, 0.8)" 
  size = "medium" 
  color = "#fff" 
  type = "line-scale-pulse-out" 
  [fullScreen] = "true"></ngx-spinner>
      <app-header *ngIf="isLogged" (sidenavToggle)="sidenav.toggle()"></app-header>
      <main *ngIf="!loading">
        <app-alert></app-alert>
        <router-outlet></router-outlet>
      </main>
      <app-topbar></app-topbar>
</app-layout>
