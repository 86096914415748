<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
    <div class="container-xxl" >
        <div class="row">
            <div class="col-lg-12">
                <div class="bg-white shadow rounded">
                    <div class="row">
                        <div class="col-md-7 pe-0">
                            <div class="form-left h-100 py-5 px-5">
  
                                <div class="row text-end" style="padding:10px;" >
                                    <div class="col-md-12">
                                        <img *ngIf="environment.languages.includes('en')" src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                        <img *ngIf="environment.languages.includes('de')"  src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                        <img *ngIf="environment.languages.includes('ro')"  src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                                </div>  
       
                              </div>
                              <div class="row mobile-logo">
                                <div class="col-12 text-center" style="margin-bottom:30px;">
                                    <img src="../../assets/images/logo-vertical.png" width="30%">
                                </div>
                            </div>                     
                                
                              <div *ngIf="!mailSent">
                                  <div class="row mt-5">
                                      <div class="col-12 text-center">
                                        <h3 class="text-center font-weight-bold text-uppercase py-4 mb-5">
                                            {{'login.user_inactive_title' | translate}}
                                            </h3>
                                      </div>
                                  </div>
                                <div class="row mt-5">
                                  <div class="col-12 text-center">
                                    <i class="fa fa-envelope fa-lg success-icon mb-5" aria-hidden="true"></i>
                                  </div>
                              </div>
                                  
                              <div class="row mt-3">
                                <div class="col-12 text-center">
                                    <b >{{'login.your_email' | translate}} <span class="text-success">{{mailAddress}}</span></b>
                                </div>
                              </div>
                        <div class="row p-3 text-center">
                        <p [innerHtml]="'login.user_inactive_text' |translate"></p>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 mx-auto text-center mb-5 mt-5">
                               <span class="btn btn-primary" (click)="resendMail()">{{'login.resend_activation_mail' | translate}}</span>
                            </div>
                           </div>
                              </div>
                              <div *ngIf ="mailSent">
                                <div class="row mt-5">
                                    <div class="col-12 text-center">
                                      <i class="fa fa-check-circle fa-lg success-icon mb-5" aria-hidden="true"></i>
                                    </div>
                                </div>
                                    <div class="row p-3 text-center">
                                      <p>{{'register.confirm_mail_msg' | translate}}</p>
                                      </div>
                                    
                                    <div class="row">
                                     <div class="col-lg-4 col-sm-12 mx-auto text-center mb-5">
                                        <a [routerLink]="['/']" class="btn btn-primary "> {{'login.login' | translate}}</a>
                                     </div>
                                    </div>
                                    
                              </div>
                            </div>
                        </div>
                        <div class="col-md-5 ps-0 d-none d-md-block">
                            <div class="form-right h-100 bg-primary text-white text-center pt-5">
                              <img src="../../assets/images/logo-vertical.png" width="50%">
  
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>