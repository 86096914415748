import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { CookieService } from 'ngx-cookie-service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
              private cookieService: CookieService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    console.log(this.currentUser);
    if (localStorage.getItem('currentUser') === null && this.cookieService.check('currentUser')){
      // console.log('set cookie');
      this.currentUserSubject = new BehaviorSubject<User>((JSON.parse(decodeURIComponent(this.cookieService.get('currentUser')))));

    }
    this.currentUser = this.currentUserSubject.asObservable();
    console.log(this.currentUser);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string, rememberMe) {
    console.log(username, password, rememberMe);
    const body = new HttpParams()
      .set('email', username)
      .set('password', password);
    return this.http.post<any>(`${environment.apiUrl}/login`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (rememberMe){
            localStorage.setItem('currentUser', JSON.stringify({token: user.token}));
          }else{
            console.log('set cookie');
            this.cookieService.set('currentUser', JSON.stringify({token: user.token}));
          }
          this.currentUserSubject.next(user);
        }

        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.cookieService.delete('currentUser', '/');
    this.currentUserSubject.next(null);

    return this.http.post<any>(`${environment.apiUrl}/logout`,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .pipe(first())
      .subscribe(data => {
        console.log(data);
      });
  }
}
