<ngx-spinner 
name = "fullSpinner"
bdColor = "rgba(0, 0, 0, 0.8)" 
size = "medium" 
color = "#fff" 
type = "line-scale-pulse-out" 
[fullScreen] = "true">
<p style="color: white" >{{'global.loading' | translate}}</p>
</ngx-spinner>
<div class="container-xxl">
  <div class="card" *ngIf="profileForm">
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium"></ngx-spinner>
    <div>
      <h3 class="card-header text-center font-weight-bold text-uppercase py-4">
        {{'profile.user_profile' | translate}}
      </h3>
    </div>
    <div class="card-body">
      <div class="form-container col-md-12 col-lg-10 col-xl-8 mx-auto">
        <form [formGroup]="profileForm" (ngSubmit)="profileSubmit()">
          <div class="row mb-5">
            <div class="col-12 col-sm-auto mb-3">
              <div class="mx-auto" style="width: 140px;">
                <figure class="rounded logo-container">
                  <img src={{imageSrc}} alt='missing' class="logo">

                  <span class="btn btn-primary btn-file upload-button " style="width:100%">
                    <i class="fa fa-fw fa-camera"></i>
                    {{'profile.change_logo' | translate}}<input type="file" formControlName='logo'
                      (change)="fileUploaded($event)">
                  </span>
                </figure>

              </div>
            </div>
            <div *ngIf="userData" class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
              <div class="text-center text-sm-left mb-2 mb-sm-0">
                <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{{userData['firstname']}} {{userData['lastname']}} </h4>
                <p class="mb-0"><b>{{userData['company']}}</b></p>
                <div class="mt-2">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.firstname' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.firstname.invalid && profileSubmitted" formControlName="firstname" >
                  <div *ngIf="fProfile.firstname.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.firstname.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.firstname' | translate } }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.lastname' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.lastname.invalid && profileSubmitted" formControlName="lastname" >
                  <div *ngIf="fProfile.lastname.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.lastname.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.lastname' | translate } }}</div>
                    </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.email' | translate }}</label>
                <input type="email" class="form-control" [class.is-invalid]="fProfile.email.invalid && profileSubmitted" formControlName="email" >
                  <div *ngIf="fProfile.email.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.email.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.email' | translate } }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.phone' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.phone.invalid && profileSubmitted" formControlName="phone" >
                  <div *ngIf="fProfile.phone.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.phone.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.phone' | translate } }}</div>
                    </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.company' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.company.invalid && profileSubmitted" formControlName="company" >
                  <div *ngIf="fProfile.company.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.company.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.company' | translate } }}</div>
                    </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.address1' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.address1.invalid && profileSubmitted" formControlName="address1" >
                  <div *ngIf="fProfile.address1.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.address1.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.address1' | translate } }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.address2' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.address2.invalid && profileSubmitted" formControlName="address2" >
                  <div *ngIf="fProfile.address2.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.address2.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.address2' | translate } }}</div>
                    </div>
              </div>
            </div>

          </div>
          <div class="row input-row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.postcode' | translate }}</label>
                <input type="text" class="form-control" [class.is-invalid]="fProfile.postcode.invalid && profileSubmitted" formControlName="postcode" >
                  <div *ngIf="fProfile.postcode.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.postcode.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.postcode' | translate } }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3" [ngClass]="{'is-invalid': profileSubmitted && fProfile.countryid.invalid}">
                <label  class="form-label">{{ 'register.country' | translate }}</label>
                <ng-select 
                [ngClass]="{'is-invalid': profileSubmitted && fProfile.countryid.invalid}"
                [items]="countries" placeholder="{{countryIdPlaceholder}}"
                (clear)="countryCleared()"
                (change)="countrySelected()" 
                [minTermLength]=1 
                [searchable]="true"
                [clearable]="true" 
                bindLabel="name" 
                bindValue="id" 
                loadingText="Loading..."
                formControlName="countryid">
                </ng-select>
                <div *ngIf="fProfile.countryid.invalid && profileSubmitted">
                  <div *ngIf="fProfile.countryid.errors?.required" class="text-danger">{{ 'register.validator_required' |
                    translate: {value: 'register.country' | translate } }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.taxnumber' | translate }}</label>
                <input type="text" class="form-control" formControlName="taxnumber"  [class.is-invalid]="fProfile.taxnumber.invalid && profileSubmitted">
                  <div *ngIf="fProfile.taxnumber.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.taxnumber.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.taxnumber' | translate } }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.vatnumber' | translate }}</label>
                <input type="text" class="form-control"  formControlName="vat" [class.is-invalid]="fProfile.vat.invalid && profileSubmitted" >
                  <div *ngIf="fProfile.vat.invalid && profileSubmitted" class="invalid-feedback">
                    <div  *ngIf="fProfile.vat.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.vatnumber' | translate } }}</div>
                    </div>
              </div>

            </div>
          </div>
          <div class="row text-right mt-4">
            <div class="col-12">
              <button [disabled]="loading" type="submit"
                class="btn btn-primary">{{'profile.save_profile' | translate}}<span
                  *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
              <button class="btn btn-danger ml-3" mwlConfirmationPopover
                data-placement="top" data-toggle="tooltip" data-original-title="Delete"
                [popoverTitle] = "'clients_list.confirmation'|translate"
                [popoverMessage]= "'client_form.cancel_changes'|translate"
                [confirmText] = "'global.confirm'| translate"
                [cancelText] = "'global.cancel'| translate"
                placement="left"
                (click)="$event.preventDefault()" 
                (confirm)="cancel()"> {{'client_form.add_cancel' | translate}}
              </button>
            </div>
          </div>
        </form>
        <form [formGroup]="changePasswdForm" (ngSubmit)="passSubmit()">
          <div class="row mt-5 mb-5">
            <div class="col-12">
              <h4>{{'profile.change_password' | translate}}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.oldpassword' | translate }}</label>
                <input type="password" class="form-control" [class.is-invalid]="fPass.oldpassword.invalid && passSubmitted" formControlName="oldpassword" >
                  <div *ngIf="fPass.oldpassword.invalid && passSubmitted" class="invalid-feedback">
                    <div  *ngIf="fPass.oldpassword.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.oldpassword' | translate } }}</div>
                    </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.password' | translate }}</label>
                <input type="password" class="form-control" [class.is-invalid]="fPass.password.invalid && passSubmitted" formControlName="password" >
                  <div *ngIf="fPass.password.invalid && passSubmitted" class="invalid-feedback">
                    <div  *ngIf="fPass.password.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.password' | translate } }}</div>
                    <div  *ngIf="fPass.password.errors?.minlength" >{{ 'register.min_length' | translate }}</div>
                    </div>
              </div>
            </div>
            <div class="col-md-6 input-row">
              <div class="mb-3">
                <label  class="form-label">{{ 'register.confirmpassword' | translate }}</label>
                <input type="password" class="form-control" [class.is-invalid]="fPass.confirmpassword.invalid && passSubmitted" formControlName="confirmpassword" >
                  <div *ngIf="fPass.confirmpassword.invalid && passSubmitted" class="invalid-feedback">
                    <div  *ngIf="fPass.confirmpassword.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.confirmpassword' | translate } }}</div>
                    <div *ngIf="fPass.confirmpassword.errors.mustMatch">{{'register.password_must_match'|translate}}</div>
                    </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button [disabled]="loading" type="submit"
                class="btn btn-primary px-4 float-end mt-4">{{'register.save_passwd' | translate}}
                <span *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"></span></button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>