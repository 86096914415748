import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
@Component({
    selector: 'app-register-confirm',
    templateUrl: './register-confirm.component.html',
    styleUrls: ['./register-confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {
    hash = '';
    email = '';
    lang = '';
    loading = true;
    successActivate = false;
    environment = environment;
    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.hash = params[`hash`];
            this.email = params[`email`];
        });
    }

    ngOnInit(): void {
        const script = this.renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `gtag('event', 'conversion', {'send_to': 'AW-1017005541/gnWXCM721KsDEOWL-eQD'});`;
        this.renderer2.appendChild(this.document.body, script);
        this.registerConfirm();
    }
    switchLang(lang) {
        // alert(lang);
        this.translate.use(lang);
        this.lang = lang;
    }

    registerConfirm() {
        let body = new HttpParams()
            .set('email', this.email);
        body = body.append('hash', this.hash);
        this.userService.userActivate(body)
            .subscribe({
                next: data => {
                    this.loading = false;
                    if (data[`success`] === true) {
                        this.successActivate = true;
                    } else {
                        this.toastr.error(this.translate.instant('register.invalid_link'));
                    }
                },
                error: () => {
                    this.loading = false;
                    this.toastr.error(this.translate.instant('register.invalid_link'));
                }
            });
    }
}
