import {Pipe, PipeTransform} from '@angular/core';
import { __assign } from 'tslib';

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
    name: 'invoicesFilter'
})
export class InvoicesFilterPipe implements PipeTransform{

    transform(value: string[], q: string) {
        if (!q || q === '') {

            return value;

        }

        const filteredInvoices = value.filter(item => -1 < item[`clientname`].toLowerCase().indexOf(q.toLowerCase()));
       // return value.filter(item => -1 < item['fullname'].toLowerCase().indexOf(q.toLowerCase()));

        return filteredInvoices;
    }
}


