<!-- nav -->
<!--<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
    <a class="nav-item nav-link" routerLink="/">Home</a>
    <a class="nav-item nav-link" routerLink="/dashboard">Dashboard</a>
    <a class="nav-item nav-link" routerLink="/admin" *ngIf="isAdmin">Admin</a>
    <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav>-->
  <div fxHide.gt-xs>
    <button (click)="onToggleSidenav()">
      menu
    </button>
  </div>
  <div>
    <a routerLink="/home">Owner-Account</a>
  </div>
  <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li><a class="nav-item nav-link" routerLink="/dashboard">Postit</a></li>
      <!--<li><a class="nav-item nav-link" routerLink="/admin" *ngIf="isAdmin">Admin</a></li>-->
      <li></li>
      <li><a routerLink="/clients" class="btn btn-link">Clients</a></li>
      <li><a routerLink="/invoices" class="btn btn-link">Invoices</a></li>
      <li><a routerLink="/products" class="btn btn-link">Products</a></li>
      <li><a routerLink="/profile" class="btn btn-link">Profile</a></li>
      <li></li>
      <li><a class="nav-item nav-link" (click)="logout()">Logout1</a></li>
    </ul>
  </div>
