import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormControl,FormGroup,Validators } from '@angular/forms';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  buildedForm: FormGroup = new FormGroup({});
  formFields: any = [];
  loader: boolean = true;
  private formConfig: any = {
    "fields": {
      "email": {
        "required": true,
        "type": "email"
      },
      "fullname": {
        "required": true,
        "type": "string"
      },
      "company": {
        "required": true,
        "type": "string"
      },
      "address": {
        "required": true,
        "type": "string"
      },
      "phone": {
        "required": true,
        "type": "phone"
      },
      "active":{
        "required":true,
        "type": "boolean"
      }
    },
    "relations": {
      "country": {
        "required": true,
        "itemtype": "country",
        "field": "countryid"
      }
  }
  };
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.buildedForm = this.fb.group({});
    this.addFormFields();

              }
  get f(): any{
      return this.buildedForm.controls;
    }

  addFormFields(){
    if(this.formConfig.fields){
      this.toArray(this.formConfig.fields).forEach(field =>{
        this.formFields.push(field);
      })
    }
    if(this.formConfig.relations){
      this.toArray(this.formConfig.relations).forEach(field =>{
        this.formFields.push(field);
      })
    }
    
    this.loader = false;
    
    this.formFields.forEach( field =>{
      
      this.buildedForm.addControl(field.name, new FormControl(''));
    })
  }

  toArray(objectsList){
    var fieldNames = Object.keys(objectsList);
    var fieldValues = Object.values(objectsList);
    for(var i = 0; i< fieldValues.length; i++){
      fieldValues[i]['name'] = fieldNames[i];
    }
    return fieldValues;
  }
  submit(){
    
    alert('submit');
  }


}
