import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          let newEvent: HttpEvent<any>;
          const user = ev.body.global.user;

          if (!user || !user.id) {
            this.logout();
          }

          newEvent = ev.clone({
            // alter event params here
            body: ev.body.data || ev.body
          });
          return newEvent;
        } else {
          // this.logout();
        }
      }),
      catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.logout();
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  logout() {
    //
    this.authenticationService.logout();
    // location.reload(true);
  }
  goToPayment(){
    this.router.navigate(['/payment']);
  }
}
