import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient
  ) { }

  pay(months){
    return this.http.get(`${environment.apiUrl}/pay/?months=${months}` , { withCredentials: true });
  }
}
