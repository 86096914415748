import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '@app/_models';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthenticationService, ItemService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@app/_services/theme.service';
import { SettingsService } from '@app/_services';
import { environment } from '@environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    currentUser: User;
    userDetails = {};
    paidUntil = '';
    currentUrl = '';
    daysToExpire = 0;
    topBarDisplay = false;
    lang = null;
    theme = 'bootstrap';
    userSettings = { lang: null, theme: 'bootstrap' };
    menuVisible = false;
    environment = environment;
    userInitials = '';
    @Output() public sidenavToggle = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private router: Router,
        private settingsService: SettingsService,
        private authenticationService: AuthenticationService,
        private themeService: ThemeService,
        private itemService: ItemService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.currentUrl = window.location.href;
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const menuCheckbox = document.getElementById('menu__toggle') as HTMLInputElement;
                if (menuCheckbox) {
                    menuCheckbox.checked = false;
                }

            }
        });
    }

    ngOnInit() {
        this.getSettings();
        this.getUser();
        // this.lang = this.translate.currentLang;
    }


    getUser(){
        this.itemService.getAll('user')
        .subscribe({
            next: response => {
                this.userDetails = response[`global`][`user`];
                this.subscriptionAvailability();
                this.getUserInitials();
            },
            error: () => {
                this.topBarDisplay = true;
            }
        });
    }
    getUserInitials(){
        if (this.userDetails[`company`] !== ''){
            return this.userDetails[`company`].charAt(0).toUpperCase();
        }
        if (this.userDetails[`firstname`] !== '' && this.userDetails[`lastname`] !== ''){
            const initials = this.userDetails[`firstname`].charAt(0).toUpperCase() + this.userDetails[`lastname`].charAt(0).toUpperCase();
            return initials;
        }
        return this.userDetails[`email`].charAt(0).toUpperCase();
    }
    subscriptionAvailability() {

        const paidUntil = new Date(this.userDetails[`paiduntil`]);
        let today = new Date();
        console.log(today);
        const threeMontsFromNow = new Date(today.setMonth(today.getMonth() + 3));
        today = new Date();
        const timeinmilisec = paidUntil.getTime() - today.getTime();
        this.daysToExpire = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
        if (threeMontsFromNow > paidUntil) {
           // this.topBarDisplay = true;
           this.topBarDisplay = false;
        } else {
            this.topBarDisplay = false;
        }

    }

    logout() {
        this.authenticationService.logout();
        this.settingsService.deleteSettings();
        this.themeService.setTheme('bootstrap');
        this.router.navigate(['/']);
    }

    getSettings = async () => {
        const settings = await this.settingsService.getModuleSettings('userSettings');
        if (settings){
            this.userSettings = settings;
        }

        if (this.userSettings && this.userSettings.hasOwnProperty('lang')) {
            this.lang = this.userSettings[`lang`];
            this.switchLang(this.lang);
            console.log('user lang ' + this.lang);
        } else {
            this.lang = this.translate.getDefaultLang();

        }
        if (this.userSettings && this.userSettings.hasOwnProperty('theme')) {
            this.theme = this.userSettings[`theme`];
            this.themeService.setTheme(this.theme);
        } else {
            this.themeService.setTheme('bootstrap');
            this.theme = 'bootstrap';
        }
    }
    openMenu(){
        let elem = document.getElementById('navbarDropdown');
        let evt = new MouseEvent('click',{
            bubbles: true,
            cancelable: true,
            view: window
        });
        elem.dispatchEvent(evt);
    }
    toggleTheme() {

        if (this.theme === 'bootstrap') {

            this.theme = 'bootstrap-dark';
            this.userSettings[`theme`] = this.theme;
        } else {
            this.theme = 'bootstrap';
            this.userSettings[`theme`] = this.theme;
        }

        this.themeService.setTheme(this.theme);
        this.updateSettings();
    }
    toggleMenu() {
        this.menuVisible = !this.menuVisible;
    }

    switchLang(lang) {
        this.translate.use(lang);
        this.lang = lang;
        this.userSettings[`lang`] = lang;
        this.updateSettings();
    }
    updateSettings = async () => {
        if (!this.currentUrl.includes('payment')) {
            console.log(typeof (this.userSettings));
            console.log(this.userSettings);
            await this.settingsService.saveModuleSettings('userSettings', this.userSettings);
        }
        //
    }
    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
    }
}
