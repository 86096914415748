<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
    <div class="container-xxl">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="bg-white shadow rounded">
                    <div class="row">
                        <div class="col-md-7 pe-0">
                          <div class="row text-end" style="padding:10px;" >
                            <div class="col-md-12">
                                <img *ngIf="environment.languages.includes('en')" src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                <img *ngIf="environment.languages.includes('de')"  src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                <img *ngIf="environment.languages.includes('ro')"  src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                        </div>  
   
                          </div>
                            <div class="form-left h-100 py-5 px-5">
                                  <div class="row mobile-logo">
                                      <div class="col-12 text-center" style="margin-bottom:30px;">
                                          <img src="../../assets/images/logo-vertical.png" width="30%">
                                      </div>
                                  </div>
                                  <div *ngIf="!mailSent"> 
                                <div class="row mt-5 mb-5">
                                    <p class="text-center">
                                        {{'profile.passch_set_mail' | translate}}
                                    </p>
                                </div>
                                <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                                <div class="row input-row">
                                        <div class="col-12">
                                            <div  class="mb-1">
                                                <label  class="form-label">{{ 'register.email' | translate }}</label>
                                                <input type="email" class="form-control" [class.is-invalid]="f.email.invalid && submitted" formControlName="email" >
                                                  <div *ngIf="f.email.invalid && submitted" class="invalid-feedback">
                                                    <div  *ngIf="f.email.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.email' | translate } }}</div>
                                                    <div  *ngIf="f.email.errors?.email" >{{ 'client_form.invalid_email' | translate }}</div>
                                                    </div>
                                              </div>
                                        </div>
                                      </div>
                                        <div class="row mb-5">
                                            <div class="col-6 text-start">
                                                <button  type="submit" class="btn btn-secondary" (click)="back()"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> {{'login.login' | translate}}</button>
                                            </div>
                                        <div class="col-6 text-right">
                                            <button [disabled]="loading" type="submit" class="btn btn-primary float-end">{{'register.submit' | translate}}<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
                                        </div>
                                      </div>
                                      </form>
                                    </div>
                                    <div *ngIf="mailSent">
                                        <div class="row mt-5 mb-5">
                                            <p class="text-center" [innerHtml] = "'profile.passch_mail_sent' | translate">
                                                
                                            </p>
                                        </div>
                                        <div class='row mt-5 mb-5'>
                                            <div class="col-12 text-center">
                                                <a [routerLink]="['/login']" class='btn btn-primary'>{{'login.login' | translate}}</a>
                                            </div>
                                           
                                        </div>
                                        <div class='row mt-5 mb-5'>
                                            <p class="text-center">
                                                *{{'profile.check_spam' | translate}}
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-md-5 ps-0 d-none d-md-block">
                            <div class="form-right h-100 bg-primary text-white text-center pt-5">
                              <img src="../../assets/images/logo-vertical.png" width="50%">
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>
  
