import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent, HeaderComponent } from '@app/_shared';
import { HeaderDashboardComponent } from './header-dashboard/header-dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { AlertComponent } from '@app/_components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HeaderDashboardComponent,
    LayoutComponent,
    AlertComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    HeaderDashboardComponent,
    LayoutComponent,
    AlertComponent,
    TranslateModule
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    NgbModule
  ]
})
export class SharedModule { }
