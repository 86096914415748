import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-terms-de',
  templateUrl: './terms-de.component.html',
  styleUrls: ['./terms-de.component.scss']
})
export class TermsDeComponent implements OnInit {
 environment = environment;
  constructor() { }

  ngOnInit(): void {
  }

}
