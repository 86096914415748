export class Contact {
  id: number;
  name: string;
  fullname: string;
  address: string;
  email: string;
  phone: string;
  company: string;
  countryid: string;
  user: string;
}
