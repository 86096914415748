<div class="login-page bg-light" style="background-image: url(../../assets/images/login-background.jpg);">
    <div class="container-xxl">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="bg-white shadow rounded">
                    <div class="row">
                        <div class="col-md-7 pe-0">
                          <div class="row text-end" style="padding:10px;" >
                            <div class="col-md-12">
                                <img *ngIf="environment.languages.includes('en')" src="../../../assets/flags/en.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='en'}" (click)="switchLang('en')">
                                <img *ngIf="environment.languages.includes('de')"  src="../../../assets/flags/de.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='de'}" (click)="switchLang('de')">
                                <img *ngIf="environment.languages.includes('ro')"  src="../../../assets/flags/ro.png" class="flag-image ml-2" [ngClass] = "{selected: lang ==='ro'}" (click)="switchLang('ro')">
                        </div>  
   
                          </div>
                            <div class="form-left h-100 py-5 px-5">
                                  <div class="row mobile-logo">
                                      <div class="col-12 text-center" style="margin-bottom:30px;">
                                          <img src="../../assets/images/logo-vertical.png" width="30%">
                                      </div>
                                  </div>
                                <div class="row mt-5 mb-5">
                                    <p class="text-center">
                                        {{'profile.enter_new_password' | translate}} <br><strong>{{email}}</strong>
                                    </p>
                                </div>
                                <form [formGroup]="passResetForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-12 input-row">
                                          <div class="mb-3">
                                            <label  class="form-label">{{ 'register.password' | translate }}</label>
                                            <input type="password" class="form-control" [class.is-invalid]="f.password.invalid && submitted" formControlName="password" >
                                              <div *ngIf="f.password.invalid && submitted" class="invalid-feedback">
                                                <div  *ngIf="f.password.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.password' | translate } }}</div>
                                                <div  *ngIf="f.password.errors?.minlength" >{{ 'register.min_length' | translate }}</div>
                                                </div>
                                          </div>
                                        </div>
                                        <div class="col-md-12 input-row">
                                          <div class="mb-3">
                                            <label  class="form-label">{{ 'register.confirmpassword' | translate }}</label>
                                            <input type="password" class="form-control" [class.is-invalid]="f.confirmpassword.invalid && submitted" formControlName="confirmpassword" >
                                              <div *ngIf="f.confirmpassword.invalid && submitted" class="invalid-feedback">
                                                <div  *ngIf="f.confirmpassword.errors?.required" >{{ 'register.validator_required' | translate: {value: 'register.confirmpassword' | translate } }}</div>
                                                <div *ngIf="f.confirmpassword.errors.mustMatch">{{'register.password_must_match'|translate}}</div>
                                                </div>
                                          </div>
                                        </div>
                                      </div>
                                        <div class="row mb-5">
                                        <div class="col-12">
                                            <button [disabled]="loading" type="submit" class="btn btn-primary px-4 float-end mt-4">{{'register.submit' | translate}}<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
                                        </div>
                                      </div>
                                      </form>
                            </div>
                        </div>
                        <div class="col-md-5 ps-0 d-none d-md-block">
                            <div class="form-right h-100 bg-primary text-white text-center pt-5">
                              <img src="../../assets/images/logo-vertical.png" width="50%">
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>
  

