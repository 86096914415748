<div class='container'>
    <div class='card' *ngIf="translate.currentLang === 'en'">
        <h3 class='card-header text-center font-weight-bold text-uppercase py-4 mb-5'>
            DOMAIN SETTINGS
          </h3>
          <div class='row mt-5'>
              <div class='col-12 text-center'>
                <i class='fa fa-info-circle info-icon mb-5' aria-hidden='true'></i>
              </div>
          </div>
    
    <div class='row p-3 text-left'>
        <div class='col-lg-8 col-sm-12 mx-auto'>
            <p>If you have a purchased domain name, in order to direct your site to the one generated by the peezzer platform, you need to make some changes to the domain server. </p><br>
            <p>
If you own a purchased domain, you can use it so that visitors visiting http://www.yourdomain.com or http://yourdomain.com are redirected to the generated site.</p><br>
<p>
To do this, you'll need to make some changes to the domain administration page (this is usually available on the site where you purchased the domain).
For example, to associate yourdomain.com site with the IP address of the Peezzer server that hosts the generated page, an A Record entry must be created with the following specifications:</p><br>
<p>
    
<img src='../../assets/images/dns-a.JPG' class='img-fluid img-thumnail mt-3 mb-1' >


    <i><b>Hostname:</b> @ (meaning the root address of the site, ie yourdomain.com)</i><br>
    <i><b>IP Address:</b> 142.132.204.180 (this is the Peezzer server address)</i><br>
<br>
<br>



</p>
<p>
    The existence of this record in the DNS zone will cause all requests to yourdomain.com to be sent to the peezzer server.</p>
    <p>
            Also, to make www.yourdomain.com accessible, a registration must be added as follows:<br>

            <img src='../../assets/images/dns-a-www.JPG' class='img-fluid img-thumnail mt-3 mb-1' >

            <i><b>Hostname:</b> www (meaning the root address of the site, ie yourdomain.com)</i><br>
            <i><b>IP Address:</b> 142.132.204.180 (this is the Peezzer server address)</i><br>
            </p>
    <br>
    <br>
    <p>Depending on your domain manager you can find more explicit instruction on following websites:</p>
    <ul >
        <li ><a href='https://www.domain.com/help/article/dns-management-how-to-update-dns-records' target='_blank'>Domain.com</a></li>
        <li ><a href='https://www.godaddy.com/garage/configuring-and-working-with-domains-dns/' target='_blank'>GoDaddy.com</a></li>
        <li ><a href='https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain/' target='_blank'>NameCheap.com</a></li>
        <li ><a href='https://www.name.com/support/articles/206127137-Adding-DNS-records-and-templates' target='_blank'>Name.com</a></li>
      </ul>
        </div>
    
    </div>
    <div class='row p-3 text-center mb-5'>
      <p>*<b>Notice: DNS changes may take up to 72 hours to propagate</b></p>
      </div>

    </div>
    <div class='card' *ngIf="translate.currentLang === 'de'">
        <h3 class='card-header text-center font-weight-bold text-uppercase py-4 mb-5'>
            DOMAIN-EINSTELLUNGEN
          </h3>
          <div class='row mt-5'>
              <div class='col-12 text-center'>
                <i class='fa fa-info-circle info-icon mb-5' aria-hidden='true'></i>
              </div>
          </div>
    
    <div class='row p-3 text-left'>
        <div class='col-lg-8 col-sm-12 mx-auto'>
            <p>Wenn Sie einen gekauften Domainnamen haben, müssen Sie einige Änderungen am Domainserver vornehmen, um Ihre Website auf die von der Peezzer-Plattform generierte zu leiten. </p><br>
            <p>
Wenn Sie eine gekaufte Domain besitzen, können Sie diese verwenden, damit Besucher, die http://www.yoursite.com oder http://yoursite.com besuchen, auf die generierte Website umgeleitet werden.</p><br>
<p>
Dazu müssen Sie einige Änderungen an der Domain-Verwaltungsseite vornehmen (diese ist normalerweise auf der Website verfügbar, auf der Sie die Domain erworben haben).
Um beispielsweise die Website yourdomain.com mit der IP-Adresse des Peezzer-Servers zu verknüpfen, der die generierte Seite hostet, muss ein A-Record-Eintrag mit den folgenden Spezifikationen erstellt werden:</p><br>
<p>
    
<img src='../../assets/images/dns-a.JPG' class='img-fluid img-thumnail mt-3 mb-1' >


    <i><b>Hostname:</b> @ (bedeutet die Stammadresse der Website, dh yourdomain.com)</i><br>
    <i><b>IP-Adresse:</b> 142.132.204.180 (dies ist die Peezzer-Serveradresse)</i><br>
<br>
<br>



</p>
<p>
    Das Vorhandensein dieses Eintrags in der DNS-Zone bewirkt, dass alle Anfragen an yourdomain.com an den Peezzer-Server gesendet werden.</p>
    <p>
            Um www.ihredomain.com zugänglich zu machen, muss außerdem eine Registrierung wie folgt hinzugefügt werden:<br>

            <img src='../../assets/images/dns-a-www.JPG' class='img-fluid img-thumnail mt-3 mb-1' >

            <i><b>Hostname:</b> www (bedeutet die Stammadresse der Website, dh yourdomain.com)</i><br>
            <i><b>IP-Adresse:</b> 142.132.204.180 (dies ist die Peezzer-Serveradresse)</i><br>
            </p>
    <br>
    <br>
    <p>Abhängig von Ihrem Domain-Manager finden Sie auf den folgenden Websites genauere Anweisungen:</p>
    <ul>
        <li ><a href='https://www.domain.com/help/article/dns-management-how-to-update-dns-records' target='_blank'>Domain.com</a></li>
        <li ><a href='https://www.godaddy.com/garage/configuring-and-working-with-domains-dns/' target='_blank'>GoDaddy.com</a></li>
        <li ><a href='https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-a-address-record-for- meine-domain/' target='_blank'>NameCheap.com</a></li>
        <li ><a href='https://www.name.com/support/articles/206127137-DNS-Einträge-und-Vorlagen-hinzufügen' target='_blank'>Name.com</a></li >
      </ul>
        </div>
    
    </div>
    <div class='row p-3 text-center mb-5'>
      <p>*<b>Hinweis: Es kann bis zu 72 Stunden dauern, bis DNS-Änderungen weitergegeben werden</b></p>
      </div>
    </div>     <div class='card' *ngIf="translate.currentLang === 'ro'">
        <h3 class='card-header text-center font-weight-bold text-uppercase py-4 mb-5'>
            SETĂRI DOMENIU
          </h3>
          <div class='row mt-5'>
              <div class='col-12 text-center'>
                <i class='fa fa-info-circle info-icon mb-5' aria-hidden='true'></i>
              </div>
          </div>
    
    <div class='row p-3 text-left'>
        <div class='col-lg-8 col-sm-12 mx-auto'>
            <p>În cazul în care  dețineți un domeniu cumpărat, îl puteți folosi astfel încât vizitatorii ce vizitează http://www.domeniultau.com sau http://domeniultau.com să fie redirecționați către site-ul generat.</p><br>
<p>
    Pentru aceasta, va trebui să efectuați niște modificări în pagină de administrare a domeniului (de obicei aceasta este accesibilă în site-ul de pe care ați cumpărat domeniul).
    De exemplu, pentru a asocia site-ului yourdomain.com adresa IP a serverului Peezzer pe care este găzduită pagină generată, trebuie creată o intrare de tipul A Record cu următoarele specificații:</p><br>
<p>
    
<img src='../../assets/images/dns-a.JPG' class='img-fluid img-thumnail mt-3 mb-1' >


    <i><b>Hostname:</b> @ ((însemnând adresa rădăcina a site-lui, adică yourdomain.com)</i><br>
    <i><b>IP Address:</b> 142.132.204.180 (această fiind adresa serverului Peezzer)</i><br>
<br>
<br>



</p>
<p>
    Existența acestei înregistrări în zona DNS va determina că toate cererile către yourdomain.com să fie trimise către serverul peezzer.</p>
    <p>
            De asemenea, pentru a face accesibilă și adresa www.domeniultau.com, mai trebuie adăugată o înregistrare după cum urmează:<br>

            <img src='../../assets/images/dns-a-www.JPG' class='img-fluid img-thumnail mt-3 mb-1' >

            <i><b>Hostname:</b> www </i><br>
            <i><b>IP Address:</b> 142.132.204.180 (adresa IP a serverului Peezzer)</i><br>
            </p>
    <br>
    <br>
    <p>În cazul în care vă administrați domeniul pe unul dintre site-urile de mai jos, în link-urile următoare veți găsi instrucțiuni specifice.</p>
    <ul >
        <li ><a href='https://www.domain.com/help/article/dns-management-how-to-update-dns-records' target='_blank'>Domain.com</a></li>
        <li ><a href='https://www.godaddy.com/garage/configuring-and-working-with-domains-dns/' target='_blank'>GoDaddy.com</a></li>
        <li ><a href='https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain/' target='_blank'>NameCheap.com</a></li>
        <li ><a href='https://www.name.com/support/articles/206127137-Adding-DNS-records-and-templates' target='_blank'>Name.com</a></li>
      </ul>
        </div>
    
    </div>
    <div class='row p-3 text-center mb-5'>
      <p>*<b>Notă. Propagarea modificărilor efectuate poate dura până la 72 ore.</b></p>
      </div>

    </div>
</div>

